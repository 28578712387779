import { useEffect } from 'react'
import { CardFilter } from './CardFilter'
import { Spinner } from '../Spinners/SpinnerLoading'
import { loaderVariants } from '../../config/animates'
import {
  useFilterProvider,
  useRequestDownload,
} from '../../context/FilterProvider'
import { ContentFilter, DivQuantity, styleDiv } from './styles'

export const ContentFilterDiv = ({ idCurrent = 0, quantity = 0 }) => {
  const [_, dataResult] = useFilterProvider()
  const [loading, error] = useRequestDownload()
  const animate = {
    opacity: idCurrent === 5 ? 0 : 1,
    position: idCurrent === 5 ? 'absolute' : 'static',
  }

  useEffect(() => {
    dataResult.total = 0
  }, [idCurrent])

  return (
    <ContentFilter animate={animate}>
      <CardFilter idQuestions={idCurrent} />
      <DivQuantity>
        <p>total: {dataResult?.total ? dataResult.total : quantity}</p>
      </DivQuantity>
      <div style={styleDiv}>
        {loading && (
          <Spinner animate='animationOne' variants={loaderVariants} />
        )}
        {error && (
          <p style={{ textAlign: 'center' }}>Ha ocurrido un error inesperado</p>
        )}
      </div>
    </ContentFilter>
  )
}
