import styled from 'styled-components'

export const Container = styled.div`
  width: 75%;
  /* background-color: Red; */
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const Ul = styled.ul`
  padding: 10px 16px;
  list-style: none;
  background-color: #fbfbfb;
  border: 1px solid #eee;
  border-radius: 5px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

export const Li = styled.li`
  display: inline;
  font-size: 16px;

  & + li::before {
    content: '/';
    padding: 8px;
    color: #122;
    font-family: 'Poppins', sans-serif;
  }

  & a {
    color: #122;
  }

  & a:hover {
    text-decoration: underline;
    color: #21158e;
  }
`
