import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import { Container, ContentPaginate, Button, ButtonNumbers } from './styles'

export const Paginate = ({
  current = 0,
  prev = 0,
  next = 0,
  last = 0,
  navigatePage,
}) => {
  const handlePage = (currentPage) =>
    currentPage !== 0 &&
    currentPage !== last + 1 &&
    currentPage !== current &&
    navigatePage(currentPage)

  return (
    <Container>
      <ContentPaginate>
        <Button onClick={() => handlePage(prev)}>
          <FiChevronLeft size={17} color='gray' />
        </Button>
        {current > 1 && (
          <>
            <ButtonNumbers onClick={() => handlePage(1)}>1</ButtonNumbers>
            {current > 2 && <ButtonNumbers disabled={true}>...</ButtonNumbers>}
          </>
        )}
        {!(prev <= 0) && (
          <ButtonNumbers onClick={() => handlePage(prev)}>{prev}</ButtonNumbers>
        )}
        <ButtonNumbers onClick={() => handlePage(current)} className='active'>
          {current}
        </ButtonNumbers>
        {next < last && (
          <>
            <ButtonNumbers onClick={() => handlePage(next)}>
              {next}
            </ButtonNumbers>
            <ButtonNumbers disabled={true}>...</ButtonNumbers>
            <ButtonNumbers onClick={() => handlePage(last)}>
              {last}
            </ButtonNumbers>
          </>
        )}
        <Button onClick={() => handlePage(next)}>
          <FiChevronRight size={17} color='gray' />
        </Button>
      </ContentPaginate>
    </Container>
  )
}
