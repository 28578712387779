export const expresions = {
  name: /^[a-zA-Z0-9À-ÿ-\s.#]{1,100}$/, // Letras y espacios, pueden llevar acentos.
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  cedula: /^([0-9])*$/,
  phone: /^([0-9]{7,10})$/, // 7 a 10 digitos numericos.
  number: /^\d{1,10}$/, // any length numbers
  password: /^.{4,12}$/, // 4 a 12 digitos.
}

export const regexPassword = /^([A-Z]{1})([a-zA-Z0-9\s.+-_*]){7,15}$/

export const regexDate = /([0-9]{4})-([0-9]{2})-([0-9]{2})/
