export const BgFormSvg1 = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={856.385}
      height={559.94}
      viewBox='0 0 856.385 559.94'
      {...props}
    >
      <g data-name='Group 47'>
        <g data-name='Group 43' transform='translate(-394.293 -290.128)'>
          <path
            data-name='Rectangle 113'
            fill='#ccc'
            d='M805.32 327.084h94.644v1.803H805.32z'
          />
          <path
            data-name='Rectangle 114'
            fill='#ccc'
            d='M431.249 327.084h300.158v1.803H431.249z'
          />
          <circle
            data-name='Ellipse 115'
            cx={36.956}
            cy={36.956}
            r={36.956}
            transform='translate(394.293 291.029)'
            fill='#33b776'
          />
          <circle
            data-name='Ellipse 116'
            cx={36.956}
            cy={36.956}
            r={36.956}
            transform='translate(562.85 291.029)'
            fill='#3f3d56'
          />
          <path
            data-name='Path 748'
            d='M768.359 365.844a37.858 37.858 0 1137.858-37.858 37.858 37.858 0 01-37.858 37.858zm0-73.913a36.055 36.055 0 1036.055 36.055 36.055 36.055 0 00-36.055-36.055z'
            fill='#ccc'
          />
          <path
            data-name='Path 749'
            d='M936.916 365.844a37.858 37.858 0 1137.858-37.858 37.858 37.858 0 01-37.858 37.858zm0-73.913a36.055 36.055 0 1036.055 36.055 36.055 36.055 0 00-36.055-36.055z'
            fill='#ccc'
          />
          <path
            data-name='Path 395'
            d='M428.404 339.004a2.993 2.993 0 01-1.8-.6l-.032-.024-6.783-5.188a3.014 3.014 0 013.668-4.784l4.393 3.369 10.381-13.544a3.013 3.013 0 014.224-.558l-.064.089.066-.089a3.016 3.016 0 01.557 4.225l-12.211 15.923a3.015 3.015 0 01-2.4 1.175z'
            fill='#fff'
          />
        </g>
        <g data-name='Group 24' transform='translate(636.265)'>
          <path
            data-name='Path 552'
            d='M20.315 195.96a14.214 14.214 0 011.987 1.108l63.154-26.954 2.253-16.636 25.235-.155-1.491 38.158-83.357 22.047a14.939 14.939 0 01-.631 1.7 14.411 14.411 0 11-7.151-19.265z'
            fill='#feb8b8'
          />
          <path
            data-name='Path 553'
            d='M122.121 541.721h-17.263l-8.212-66.584h25.477z'
            fill='#feb8b8'
          />
          <path
            data-name='Path 554'
            d='M92.528 536.789h33.292v20.955H71.566a20.962 20.962 0 0120.962-20.962z'
            fill='#2f2e41'
          />
          <path
            data-name='Path 555'
            d='M189.257 536.844l-17.178 1.69-14.7-65.458 25.355-2.491z'
            fill='#feb8b8'
          />
          <path
            data-name='Path 556'
            d='M159.313 534.84l33.126-3.263 2.054 20.857-53.981 5.317a20.958 20.958 0 0118.8-22.911z'
            fill='#2f2e41'
          />
          <circle
            data-name='Ellipse 84'
            cx={34.583}
            cy={34.583}
            transform='translate(92.343 13.688)'
            fill='#feb8b8'
            r={34.583}
          />
          <path
            data-name='Path 557'
            d='M101 528.255a6.3 6.3 0 01-6.217-5.21c-8.929-49.59-38.141-211.772-38.84-216.268a1.991 1.991 0 01-.023-.312v-12.094a2.1 2.1 0 01.393-1.228l3.858-5.4a2.082 2.082 0 011.611-.88c22-1.031 94.036-4.054 97.516.294 3.495 4.365 2.253 17.611 1.971 20.22l.014.272 32.366 206.982a6.353 6.353 0 01-5.233 7.23l-20.214 3.33a6.366 6.366 0 01-7.076-4.355c-6.252-19.978-27.216-87.184-34.482-113.19a.7.7 0 00-1.381.2c.363 24.79 1.24 88.036 1.549 109.881l.032 2.353a6.362 6.362 0 01-5.763 6.387l-19.492 1.766a5.871 5.871 0 01-.589.022z'
            fill='#2f2e41'
          />
          <path
            data-name='Path 99'
            d='M104.978 96.453c-6.035 3.588-9.647 10.18-11.719 16.9a160.072 160.072 0 00-6.877 38.242l-2.191 38.859-27.113 103.028c23.5 19.883 37.053 15.363 68.686-.9s35.247 5.422 35.247 5.422l6.325-87.663 9.037-95.793a42.485 42.485 0 00-6.846-6.581 69.923 69.923 0 00-59.761-12.673z'
            fill='#e6e6e6'
          />
          <path
            data-name='Path 558'
            d='M96.172 216.767a14.828 14.828 0 012.112.986l62.447-31.259 1.036-16.933 25.759-1.776 1.38 38.6-83.45 27.6a14.784 14.784 0 11-9.283-17.223z'
            fill='#feb8b8'
          />
          <path
            data-name='Path 101'
            d='M175.923 114.98c15.363 5.422 18.071 64.171 18.071 64.171-18.075-9.941-39.765 6.326-39.765 6.326s-4.518-15.365-9.941-35.246a34.54 34.54 0 017.229-32.535s9.041-8.141 24.406-2.716z'
            fill='#e6e6e6'
          />
          <path
            data-name='Path 102'
            d='M157.929 57.414c-4.309-3.447-10.187 2.816-10.187 2.816l-3.447-31.016s-21.543 2.581-35.334-.862-15.943 12.5-15.943 12.5a110.654 110.654 0 01-.431-19.391c.862-7.756 12.064-15.512 31.885-20.683s30.162 17.236 30.162 17.236c13.796 6.889 7.609 42.851 3.295 39.4z'
            fill='#2f2e41'
          />
        </g>
        <path
          data-name='Rectangle 110'
          fill='#e6e6e6'
          d='M119 557.75h737.385v2.19H119z'
        />
        <path
          data-name='Path 438'
          d='M190.135 519.076a19.474 19.474 0 0018.806-3.313c6.587-5.528 8.652-14.636 10.332-23.07l4.97-24.945-10.405 7.165c-7.483 5.152-15.134 10.47-20.316 17.933s-7.443 17.651-3.28 25.726'
          fill='#e6e6e6'
        />
        <path
          data-name='Path 439'
          d='M191.742 551.013c-1.31-9.542-2.657-19.206-1.738-28.85.816-8.565 3.429-16.93 8.749-23.789a39.574 39.574 0 0110.153-9.2c1.015-.641 1.95.968.939 1.606a37.622 37.622 0 00-14.885 17.955c-3.24 8.241-3.76 17.224-3.2 25.977.338 5.294 1.053 10.553 1.774 15.805a.964.964 0 01-.65 1.144.936.936 0 01-1.144-.65z'
          fill='#f2f2f2'
        />
        <path
          data-name='Path 442'
          d='M201.169 535.703a14.336 14.336 0 0012.491 6.447c6.323-.3 11.595-4.713 16.34-8.9l14.036-12.392-9.289-.444c-6.68-.32-13.533-.618-19.9 1.442s-12.231 7.018-13.394 13.6'
          fill='#e6e6e6'
        />
        <path
          data-name='Path 443'
          d='M188.04 556.498c6.3-11.156 13.618-23.555 26.685-27.518a29.779 29.779 0 0111.224-1.159c1.192.1.894 1.94-.3 1.837a27.665 27.665 0 00-17.912 4.739c-5.051 3.438-8.983 8.217-12.311 13.286-2.039 3.1-3.865 6.341-5.691 9.573-.582 1.032-2.282.287-1.695-.758z'
          fill='#f2f2f2'
        />
        <g data-name='Group 46'>
          <path
            data-name='Path 138'
            d='M632.419 234.597H316.73a5.853 5.853 0 01-5.846-5.846v-78.309a5.853 5.853 0 015.846-5.846h315.689a5.853 5.853 0 015.846 5.846v78.309a5.853 5.853 0 01-5.846 5.846zM316.73 146.935a3.512 3.512 0 00-3.508 3.508v78.309a3.511 3.511 0 003.508 3.508h315.689a3.511 3.511 0 003.508-3.508v-78.31a3.511 3.511 0 00-3.508-3.508z'
            fill='#e6e6e6'
          />
          <path
            data-name='Path 139'
            d='M420.781 170.715a3.969 3.969 0 100 7.938h187.108a3.969 3.969 0 000-7.938z'
            fill='#e6e6e6'
          />
          <path
            data-name='Path 140'
            d='M420.781 194.53a3.969 3.969 0 100 7.938h80.513a3.969 3.969 0 100-7.938z'
            fill='#e6e6e6'
          />
          <g data-name='Group 45' transform='translate(-169.808 -215.403)'>
            <circle
              data-name='Ellipse 18'
              cx={23.814}
              cy={23.814}
              r={23.814}
              transform='translate(512 378)'
              fill='#e6e6e6'
            />
            <path
              data-name='Path 395'
              d='M532.071 415.049a3.689 3.689 0 01-2.219-.738l-.04-.03-8.358-6.394a3.716 3.716 0 014.52-5.9l5.414 4.151 12.793-16.69a3.713 3.713 0 015.205-.688l-.079.11.082-.11a3.717 3.717 0 01.687 5.206L535.029 413.6a3.715 3.715 0 01-2.954 1.448z'
              fill='#33b776'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const BgFormSvg2 = (props) => {
  return (
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      width={931}
      height={633.235}
      viewBox='0 0 931 633.235'
      {...props}
    >
      <g data-name='Group 41'>
        <path
          data-name='Rectangle 62'
          fill='#e6e6e6'
          d='M120.363 297.375h703.576v333.859H120.363z'
        />
        <path
          data-name='Rectangle 75'
          fill='#fff'
          d='M140.482 326.004h663.339v278.549H140.482z'
        />
        <path
          data-name='Rectangle 76'
          fill='#e6e6e6'
          d='M675.712 377.851h17.177v17.177h-17.177z'
        />
        <path
          data-name='Rectangle 77'
          fill='#33b776'
          d='M217.543 377.851h17.177v17.177h-17.177z'
        />
        <path
          data-name='Rectangle 78'
          fill='#e6e6e6'
          d='M217.046 427.053h57.258v57.258h-57.258z'
        />
        <path
          data-name='Rectangle 80'
          fill='#33b776'
          d='M120.063 278.086h703.576v29.89H120.063z'
        />
        <circle
          data-name='Ellipse 90'
          cx={142.275}
          cy={299.662}
          r={5.54}
          fill='#fff'
        />
        <circle
          data-name='Ellipse 91'
          cx={163.303}
          cy={299.662}
          r={5.54}
          fill='#fff'
        />
        <circle
          data-name='Ellipse 92'
          cx={184.332}
          cy={299.662}
          r={5.54}
          fill='#fff'
        />
        <path data-name='Rectangle 60' fill='#e6e6e6' d='M0 631.235h931v2H0z' />
        <path
          data-name='Rectangle 81'
          fill='#e6e6e6'
          d='M333.688 432.78h140.281v8.053H333.688z'
        />
        <path
          data-name='Rectangle 82'
          fill='#33b776'
          d='M333.688 462.154h216.625v8.053H333.688z'
        />
        <path
          data-name='Rectangle 83'
          fill='#e6e6e6'
          d='M333.688 489.827h176.544v8.053H333.688z'
        />
        <path
          data-name='Rectangle 84'
          fill='#e6e6e6'
          d='M333.688 517.398h103.064v8.053H333.688z'
        />
        <path
          data-name='Rectangle 85'
          fill='#e6e6e6'
          d='M333.688 544.967h155.55v8.053h-155.55z'
        />
        <path
          data-name='Rectangle 88'
          fill='#e6e6e6'
          d='M705.296 377.851h17.177v17.177h-17.177z'
        />
        <path
          data-name='Rectangle 90'
          fill='#e6e6e6'
          d='M734.879 377.851h17.177v17.177h-17.177z'
        />
        <circle
          data-name='Ellipse 88'
          cx={717.171}
          cy={490.566}
          r={34.884}
          fill='#33b776'
          opacity={0.997}
          style={{
            isolation: 'isolate',
          }}
        />
        <circle
          data-name='Ellipse 97'
          cx={188.489}
          cy={55.184}
          r={39.273}
          fill='#2f2e41'
        />
        <path
          data-name='Path 630'
          d='M90.753 391.21h-17.2l-8.181-66.327h25.379z'
          fill='#feb8b8'
        />
        <path
          data-name='Path 631'
          d='M61.275 386.295h33.163v20.881H40.394a20.881 20.881 0 0120.881-20.881z'
          fill='#2f2e41'
        />
        <path
          data-name='Path 632'
          d='M63.91 381.612l-16.406-5.149 12.052-65.735 24.214 7.6z'
          fill='#feb8b8'
        />
        <path
          data-name='Path 633'
          d='M37.246 368.1l31.642 9.93-6.252 19.923-51.564-16.182a20.881 20.881 0 0126.175-13.67z'
          fill='#2f2e41'
        />
        <path
          data-name='Path 634'
          d='M69.505 355.145a6.258 6.258 0 01-2.2-.4l-12.655-4.743a6.334 6.334 0 01-3.947-7.27c3.185-14.322 14.04-63.078 19.984-89.229 7.049-31.019 109.885-36.783 114.259-37.013l.36-.019 11.568 14.531c4.18 13.436 2.855 23.916-3.938 31.15-19.823 21.105-79.006 8.01-86.063 6.352l-31.455 82.577a6.33 6.33 0 01-5.91 4.064z'
          fill='#2f2e41'
        />
        <path
          data-name='Path 635'
          d='M87.739 364.964a6.255 6.255 0 01-2.2-.4l-12.651-4.744a6.333 6.333 0 01-3.947-7.271c3.185-14.321 14.04-63.077 19.984-89.228 7.049-31.019 109.885-36.783 114.259-37.013l.36-.019 11.564 14.535c4.18 13.436 2.855 23.916-3.938 31.15-19.822 21.1-79.006 8.01-86.063 6.351L93.648 360.9a6.33 6.33 0 01-5.91 4.064z'
          fill='#2f2e41'
        />
        <path
          data-name='Path 636'
          d='M51.138 162.955a14.799 14.799 0 011.241 1.966l69.178 7.288 9.96-13.654 22.569 12.335-19.559 33.132-84.841-21.632a14.722 14.722 0 111.454-19.436z'
          fill='#feb8b8'
        />
        <path
          data-name='Path 637'
          d='M211.897 246.388a6.314 6.314 0 01-2.875-.7c-13.833-7.034-40.769-19.215-65.747-21.764a6.161 6.161 0 01-4.342-2.454 6.338 6.338 0 01-1.169-4.924c2.254-12.477 7.424-44.249 6.758-69.712a34.5 34.5 0 0126.063-34.319 112.014 112.014 0 0113.9-2.741 34.779 34.779 0 0138.655 40.342c-4.454 26.388-8.945 63.244-5.042 88.972a6.322 6.322 0 01-2.551 6.1 6.213 6.213 0 01-3.65 1.2z'
          fill='#ccc'
        />
        <path
          data-name='Path 638'
          d='M145.803 179.27a6.314 6.314 0 01-2.506-.523l-18.021-7.8a6.319 6.319 0 01-3.285-8.3l13.93-32.179a16.13 16.13 0 1129.697 12.602l-.092.212-13.93 32.18a6.328 6.328 0 01-5.793 3.808z'
          fill='#ccc'
        />
        <circle
          data-name='Ellipse 98'
          cx={187.054}
          cy={64.377}
          r={34.45}
          fill='#feb8b8'
        />
        <path
          data-name='Path 639'
          d='M151.984 47.448a124.255 124.255 0 0053.757 17.713l-5.667-6.789a41.638 41.638 0 0012.863 2.554 14.087 14.087 0 0011.483-5.375 13.1 13.1 0 00.877-12.1 24.816 24.816 0 00-7.807-9.763 46.482 46.482 0 00-43.263-7.732 27.779 27.779 0 00-12.922 8.289c-3.266 4.029-9.553 7.619-7.881 12.528z'
          fill='#2f2e41'
        />
        <path
          data-name='Path 640'
          d='M190.134 18.427A66.918 66.918 0 01214.479 2.68 34.253 34.253 0 01232.922.391c6.153 1.238 11.971 5.494 13.688 11.531 1.4 4.936-.046 10.251-2.224 14.9s-5.086 8.956-6.848 13.776a31.441 31.441 0 0031.638 42.163c-6.045.811-11.617 3.652-17.526 5.163s-12.883 1.41-17.274-2.824c-4.646-4.479-4.739-11.761-4.514-18.21l1-28.762c.17-4.889.316-9.935-1.448-14.5s-5.954-8.56-10.846-8.518c-3.708.031-6.989 2.278-9.963 4.493s-6.121 4.578-9.814 4.908-7.91-2.4-7.634-6.1'
          fill='#2f2e41'
        />
        <path
          data-name='Path 641'
          d='M129.135 237.538a15.435 15.435 0 115.95-29.685 14.84 14.84 0 011.839.932l62.755-28.17 1.763-17.02 27.051-.725-.179 28.335a15.26 15.26 0 01-11.027 14.51l-73.385 20.884a14.455 14.455 0 01-.5 1.43 15.492 15.492 0 01-14.26 9.508z'
          fill='#feb8b8'
        />
        <path
          data-name='Path 642'
          d='M226.202 176.754a6.305 6.305 0 01-2.471.668l-19.6 1.205a6.319 6.319 0 01-6.687-5.912l-2.151-35a16.13 16.13 0 0132.2-1.981l2.152 35a6.327 6.327 0 01-3.441 6.02z'
          fill='#ccc'
        />
        <rect
          data-name='Rectangle 91'
          x={67.888}
          y={231.258}
          width={120.625}
          height={9.818}
          rx={1.778}
          fill='#3f3d56'
        />
        <path
          data-name='Path 643'
          d='M22.905 231.96v-68.729a9.128 9.128 0 019.117-9.117h124.833a9.127 9.127 0 019.117 9.117v68.73a9.127 9.127 0 01-9.117 9.116H32.022a9.128 9.128 0 01-9.117-9.117z'
          fill='#3f3d56'
        />
        <circle
          data-name='Ellipse 99'
          cx={95.94}
          cy={197.599}
          r={8.416}
          fill='#fff'
        />
      </g>
    </svg>
  )
}
