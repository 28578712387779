import { Helmet } from 'react-helmet-async'
import { Redirect, useParams } from 'react-router-dom'
import { CardRecoveryPassword } from '../../components/CardRecovery/CardRecoveryPassword'
import { userData } from '../../context/UserProvider'
import { Main, Div } from './styles'

export const RecoveryAccount = () => {
  const { token } = useParams()
  const { rol } = userData()
  if (rol === 'Graduado' || rol === 'Administrador') return <Redirect to='/' />

  return (
    <>
      <Helmet>
        <title>Recuperar cuenta</title>
      </Helmet>
      <Main>
        <Div>
          <CardRecoveryPassword token={token} />
        </Div>
      </Main>
    </>
  )
}
