import { useState, useEffect } from 'react'
import { endpointSelectOptions } from '../apis/endpointSelectOptions'
import { newValues } from '../components/Select/modificatedArray'
import { useToken } from './useToken'

export const useGetValueSelect = (endpoint = '') => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [token] = useToken()

  const getOptionsValue = async () => {
    try {
      setError(true)
      const {
        data: { message },
      } = await endpointSelectOptions(endpoint, token)
      setLoading(false)
      setOptions(newValues(message))
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    getOptionsValue()
  }, [])

  return [options, loading, error]
}
