import { motion } from 'framer-motion'
import styled from 'styled-components'
import bannerGraduate from '../../assets/img/backgroundLogin/egresados.jpg'

// tamaños => 480 | 600 | 768 | 900 | 1200

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--backgroundPage);
  /* choose primary */
  background-image: url(${bannerGraduate});
  /* choose secundary */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: right; */
  overflow: hidden;
  padding: 40px 0;

  & button.message-btn {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    /* min-height: 450px; */
  }
`

export const Modal = styled(motion.section)`
  width: 35%;

  @media screen and (max-width: 900px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 65%;
  }

  @media screen and (max-width: 600px) {
    width: 80%;
  }

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`

export const Form = styled.form`
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 3px 15px rgba(51, 51, 51, 0.2);
  background-color: var(--backgroundColor);
  margin-top: 30px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
`

export const InputWrapper = styled.div`
  margin-bottom: 30px;
  position: relative;
`

export const LabelForm = styled.label`
  font-weight: 500;
  display: block;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
`

export const InputForm = styled.input`
  height: 50px;
  font-size: 15px;
  border: 2px solid ${({ isError }) => (isError ? 'red' : 'var(--gray)')};
  width: 100%;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
  border-radius: 6px;
  color: var(--foregroundColor);
  background-color: var(--backgroundColor);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;

  &:focus,
  &:active {
    outline: none;
    border-color: var(--secunday-color);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-origin: #fff !important;
  }
`

export const Button = styled.button`
  width: 100%;
  padding: 12px;
  border: 1px solid var(--secundary-color);
  border-radius: 5px;
  font-weight: 500;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;

  &:hover {
    background-color: var(--secundary-color);
    color: #fff;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
  }
`

export const MissEmail = styled.button`
  font-size: 14px;
  justify-self: center;

  &:hover {
    text-decoration: underline;
    color: var(--secundary-color);
  }
`

export const ContentImage = styled(motion.div)`
  width: 65%;
  height: 700px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
// Message - js

export const ContainerMessage = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ContentMessage = styled.button`
  width: 80%;
  position: relative;
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(1, 13, 20, 0.5);
  color: #fff;

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`
export const MessageText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  text-align: center;
`
