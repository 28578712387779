import { useState } from 'react'
import { keyLocalStorage } from '../config/keyLocalStorage'

export const useToken = () => {
  const { tokenUser } = keyLocalStorage
  const [token, setToken] = useState(() => {
    try {
      const currentToken = localStorage.getItem(tokenUser)
      return currentToken !== null ? currentToken : null
    } catch {
      return null
    }
  })

  const handleSetToken = (value) => {
    try {
      localStorage.setItem(tokenUser, value)
      setToken(value)
    } catch {
      console.log('error token')
    }
  }

  const handleRemoveToken = () => {
    try {
      localStorage.removeItem(tokenUser)
      setToken(null)
    } catch {
      console.log('error keyToken')
    }
  }

  return [token, handleSetToken, handleRemoveToken]
}
