export const newValues = (message = []) =>
  message.map((e) => {
    const label =
      e.document_name ||
      e.ethnicity_name ||
      e.name ||
      e.occupation_name ||
      e.university_name ||
      e.formations_name ||
      e.modality_name ||
      e.program_name ||
      e.industry_name ||
      e.amount_income ||
      e.job_title ||
      e.factor_name ||
      e.name_media ||
      e.activity_name ||
      e.number_months ||
      e.month_range ||
      e.obstacle_name ||
      e.name_statements ||
      e.service_name ||
      e.name_competences ||
      e.name_genres
    const value = e.id || e.abreviation
    return { label, value }
  })

export const primaryCondMaxId = (index) =>
  index === 43 ||
  index === 49 ||
  index === 52 ||
  index === 74 ||
  index === 76 ||
  index === 77 ||
  index === 80 ||
  index === 81

export const secundConditionalMax = (index, optionSelectedLength) =>
  (index >= 43 && index <= 52 && optionSelectedLength <= 1) ||
  (index === 74 && optionSelectedLength <= 2) ||
  (index >= 76 && index <= 81 && optionSelectedLength <= 2)
