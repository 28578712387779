import { useState, useEffect, useRef } from 'react'
import { getAnswers, getQuestionsLaboral } from '../../apis/endpointQuestions'
import { useDownloadPoll } from '../../hooks/useDownloadPoll'
import { useToken } from '../../hooks/useToken'
import { ButtonOutLine } from '../Buttons/ButtonOutLine'
import { SelectFilter } from '../Select/SelectFilter'
import {
  Container,
  Card,
  DivColumn,
  ContentSelect,
  DivContentButton,
} from './styles'

export const FilterPoll = () => {
  const [questions, setQuestions] = useState([{ label: '', value: '' }])
  const [answers, setAnswers] = useState([{ label: '', value: '' }])
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const token = useToken()[0]
  const { handleExportExcel } = useDownloadPoll()
  const refQuestions = useRef(null)
  const refAnswers = useRef(null)

  const handleChangeSelect = (change, other) => {
    if (change && other) {
      const { value } = change
      const { name } = other
      setFilter({ ...filter, [name]: value })
      // const formatSelect = select.map(({ value }) => ({ value }))
      // setFilter({ ...filter, [name]: formatSelect })
    }
  }

  const getAllQuestions = async () => {
    try {
      setError(false)
      const {
        data: { message = [] },
      } = await getQuestionsLaboral(token, 7)
      const changeArrow = ({ survey_name = '', id = 0 }) => ({
        label: survey_name,
        value: id,
      })
      const formatResponse = message.map(changeArrow)
      setQuestions(formatResponse)

      const { data } = await getAnswers(message[0].possible_answer, token)
      const messageAnswers = data.message
      const handleFormat = ({ name_statements = '', id = 0 }) => ({
        label: name_statements,
        value: id,
      })
      const formatAnswers = messageAnswers.map(handleFormat)
      setAnswers(formatAnswers)
      setLoading(false)
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  const handleDownload = () => handleExportExcel(null, filter, token)

  const handleResetFilter = () => {
    setFilter({})
    refQuestions && refQuestions.current.select.clearValue()
    refAnswers && refAnswers.current.select.clearValue()
    console.log(refQuestions)
  }

  useEffect(getAllQuestions, [])

  return (
    <Container>
      {error && <h4>Ha ocurrido un error inesperado</h4>}
      <Card>
        <DivColumn>
          <ContentSelect>
            <SelectFilter
              ref={refQuestions}
              name='questions'
              titleLabel='Por pregunta'
              options={questions}
              loading={loading}
              isClearable={true}
              // isMulti={true}
              onChange={handleChangeSelect}
            />
          </ContentSelect>
          <ContentSelect>
            <SelectFilter
              ref={refAnswers}
              name='answers'
              titleLabel='Por respuesta'
              options={answers}
              loading={loading}
              // isMulti={true}
              isClearable={true}
              onChange={handleChangeSelect}
            />
          </ContentSelect>
        </DivColumn>
        <DivContentButton>
          <ButtonOutLine onClick={handleDownload}>
            Descargar filtrado
          </ButtonOutLine>
          <ButtonOutLine onClick={handleResetFilter}>
            Resetear filtro
          </ButtonOutLine>
        </DivContentButton>
      </Card>
    </Container>
  )
}
