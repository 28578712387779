import styled from 'styled-components'

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px;

  &:focus {
    border-color: #09f;
  }
`

export const InputPrimary = ({ ...otherProps }) => {
  return <Input {...otherProps} />
}
