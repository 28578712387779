import { useState } from 'react'
import { recoveryUser } from '../../apis/endpoints'
import { animateSequence, loaderVariants } from '../../config/animates'
import { expresions } from '../../config/expresions'
import { Spinner } from '../Spinners/SpinnerLoading'
import {
  Button,
  Form,
  InputForm,
  InputWrapper,
  LabelForm,
  MissEmail,
  Modal,
  ContentMessage,
  MessageText,
} from './styles'

const email = 'tic@corhuila.edu.co'
const message1 = `Si no tiene acceso o no reconoce este dirección de email, comunicarse con ${email}`

export const MissCredentials = ({ missCredential }) => {
  const [document, setDocument] = useState('')
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorPost, setErrorPost] = useState(false)
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')

  const handleChange = ({ target: { value } }) => {
    !expresions.cedula.test(value) ? setIsError(true) : setIsError(false)
    setDocument(value)
  }

  const handleMissCredential = () => missCredential(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (document !== '' && isError === false) {
      try {
        setMessage('')
        setErrorPost(false)
        setLoading(true)
        const {
          data: { message, email },
        } = await recoveryUser(document)
        setLoading(false)
        setEmail(email)
        setMessage(message)
      } catch {
        setLoading(false)
        setErrorPost(true)
      }
    }
  }

  return (
    <Modal initial='hidden' animate='visible' variants={animateSequence(0.5)}>
      <Form>
        <InputWrapper>
          <LabelForm htmlFor='document'>Número de identificación</LabelForm>
          <InputForm
            value={document}
            name='document'
            id='document'
            placeholder=''
            isError={isError}
            onChange={handleChange}
          />
        </InputWrapper>
        {message !== '' && (
          <InputWrapper>
            <p style={{ textAlign: 'center' }}>{message}</p>
            <ContentMessage
              style={{
                width: '100%',
                BackgroundColor: 'rgba(1, 13, 20, 0.3)',
                padding: 10,
                marginTop: 10,
              }}
            >
              <MessageText>{message1}</MessageText>
            </ContentMessage>
          </InputWrapper>
        )}
        {loading && (
          <InputWrapper style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner variants={loaderVariants} animate='animationOne' />
          </InputWrapper>
        )}
        {errorPost && (
          <InputWrapper>
            <p style={{ textAlign: 'center' }}>
              Ha ocurrio un error inesperado
            </p>
          </InputWrapper>
        )}
        <InputWrapper>
          <Button onClick={handleSubmit}>Recuperar</Button>
        </InputWrapper>
        <center>
          <MissEmail onClick={handleMissCredential}>Volver</MissEmail>
        </center>
      </Form>
    </Modal>
  )
}
