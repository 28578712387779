import { motion, useMotionValue, useTransform } from 'framer-motion'
import { pathVariants } from '../../config/animateSvg'
import { AnimateSvg } from './styles'

export const CheckedSvg = ({
  isHidden = false,
  size = 40,
  color = 'white',
}) => {
  const pathLength = useMotionValue(0)
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1])

  return (
    <AnimateSvg
      initial={false}
      animate={isHidden ? 'checked' : 'unChecked'}
      width={size}
      viewBox='0 0 50 50'
    >
      <motion.path
        fill='none'
        strokeWidth='2'
        d='M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0'
      />
      <motion.path
        fill='none'
        strokeWidth='2'
        stroke={color}
        d='M14,26 L 22,33 L 35,16'
        variants={pathVariants}
        strokeDasharray='0 0'
        style={{ pathLength, opacity }}
        custom={isHidden}
      />
    </AnimateSvg>
  )
}
