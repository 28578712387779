import { useState } from 'react'
import { keyLocalStorage } from '../config/keyLocalStorage'
import { userModelContext } from '../models/userContextModel'

export const useUserData = () => {
  const { userDataKey } = keyLocalStorage
  const [userData, setUserData] = useState(() => {
    try {
      const user = localStorage.getItem(userDataKey)
      return user !== null ? JSON.parse(user) : {}
    } catch {
      return {}
    }
  })

  const handleSetUserData = (value) => {
    try {
      localStorage.setItem(userDataKey, JSON.stringify(value))
      setUserData(value)
    } catch {
      setUserData({})
    }
  }

  const handleRemoveKey = () => {
    try {
      localStorage.removeItem(userDataKey)
      setUserData({})
    } catch {
      console.log('error removeKey - userData')
      // setUserData(userModelContext)
    }
  }

  return [userData, handleSetUserData, handleRemoveKey]
}
