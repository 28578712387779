import styled from 'styled-components'
import { motion } from 'framer-motion'
import { InputPrimary } from '../Inputs/InputPrimary'

export const Container = styled.div`
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
`

export const Form = styled.form``

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const Label = styled.label`
  font-size: 15px;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
`

export const InputFilter = styled(InputPrimary)`
  height: 37px;
  padding: 5px 7px;
`

export const Button = styled.button`
  width: ${({ idQuestions = 0 }) => (idQuestions !== 5 ? '49%' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secundary-color);
  border-color: ${({ colorHover = 'var(--secundary-color)' }) => colorHover};
  border-radius: 3px;
  font-size: 17px;
  padding: 5px 0;

  &:hover {
    background-color: ${({ colorHover = 'var(--secundary-color)' }) =>
      colorHover};
    color: #fff;
  }

  @media screen and (max-width: 1200px) {
    font-size: 15px;
  }
`
export const ContentFilter = styled(motion.article)`
  width: 35%;
  padding: 10px;
  margin-bottom: 40px;

  @media screen and (max-width: 900px) {
    width: 50%;
    align-self: center;
  }

  @media screen and (max-width: 768px) {
    width: 60%;
  }

  @media screen and (max-width: 600px) {
    width: 80%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

export const DivQuantity = styled(Container)`
  margin-top: 20px;

  & p {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
`

export const styleDiv = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 20,
}

export const stylesCalendar = {
  padding: '0',
}

export const stylesTextCalendar = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 15,
}

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  & button {
    margin-bottom: 5px;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 500;
  }
`
