import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  margin: 10px 20px;
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 30px;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 10px 20px;
  }
`

export const Main = styled.main`
  margin-bottom: 100px;
  padding-bottom: 20px;
  min-height: 90vh;
`
export const ContentUsers = styled.section`
  width: 100%;
  min-height: 400px;
  padding: 0 30px;
`

export const styleButton = {
  padding: '0 7px',
  margin: 0,
  height: 40,
  width: 160,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
}

export const P = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: gray;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: right;
  font-family: 'Poppins', sans-serif;
`

// part-filter

export const ContainFilterTable = styled.section`
  width: 100%;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    /*  */
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
