import { useState } from 'react'
// import styled from "styled-components"
import { Radio } from './Radio'

export const RadioButton = ({
  dataPrimary = [],
  callBackButton,
  isErrorSubmit = false,
}) => {
  const [data, setData] = useState(dataPrimary)

  const handleOptions = (radioCurrent) => {
    const filtered = data.filter(({ id }) => id !== radioCurrent.id)
    const maped = filtered.map((el) => ({ ...el, isTrue: false }))
    const result = [...maped, { ...radioCurrent }]
    const final = result.sort(({ id }, other) => id - other.id)

    setData(final)
    callBackButton(radioCurrent)
  }

  return data.map((radio) => (
    <Radio
      key={radio.id}
      {...radio}
      setState={handleOptions}
      isErrorSubmit={isErrorSubmit}
    >
      {radio.title}
    </Radio>
  ))
}
