import styled from 'styled-components'
import { ButtonOutLine } from '../Buttons/ButtonOutLine'

/* tamaños => 480 | 600 | 768 | 900 | 1200 */

const Section = styled.section`
  /* border: 1px solid red; */
  min-height: 90vh;
`

const H2 = styled.h2`
  font-size: 18px;
`

const Article = styled.article`
  /* border: 1px solid green; */
  padding: 15px 0;
`

const ContentButtons = styled.div`
  display: flex;
  justify-content: ${({ idCurrent = 1 }) =>
    idCurrent <= 1 ? 'flex-end' : 'space-between'};
  padding-right: 10px;
`

export const StructureForm = ({
  title = '',
  idForm = 0,
  callBackNext,
  callBackPrev,
  children,
}) => {
  // mejorar
  const handleNext = () => callBackNext(idForm + 1)
  const handlePrev = () => callBackPrev(idForm - 1)

  return (
    <Section>
      <H2>{title}</H2>
      <Article>{children}</Article>
      <ContentButtons idCurrent={idForm}>
        {idForm > 1 && idForm <= 5 && (
          <ButtonOutLine onClick={handlePrev}>Anterior</ButtonOutLine>
        )}
        {idForm < 6 && (
          <ButtonOutLine onClick={handleNext}>
            {idForm === 5 ? 'Terminar' : 'Siguiente'}
          </ButtonOutLine>
        )}
      </ContentButtons>
    </Section>
  )
}
