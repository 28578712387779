import { useEffect } from 'react'
import { ButtonPrimary } from '../Buttons/ButtonPrimary'
import { TableInfBasic } from './TableInfBasic'
import { TableInfAcademic } from './TableInfAcademic'
import { TableInfLabor } from './TableInfLabor'
import { TableAppreciation } from './TableAppreciation'
import { useDownloadFilter } from '../../context/FilterProvider'
import { ContainerDownloadBtn } from './styles'

export const TableInf = ({ id = 1, users = [] }) => {
  const handleDownload = useDownloadFilter()

  useEffect(() => {
    // console.log("Table-inf", id, users)
  }, [])

  return (
    <>
      {id === 1 ? (
        <TableInfBasic users={users} />
      ) : id === 2 ? (
        <TableInfAcademic users={users} />
      ) : id === 3 ? (
        <TableInfLabor users={users} />
      ) : (
        <TableAppreciation idCurrent={id} data={users} />
      )}

      {id <= 3 && (
        <ContainerDownloadBtn>
          <ButtonPrimary
            onClick={handleDownload}
            style={{ border: '1px solid #122' }}
          >
            Descargar datos completos
          </ButtonPrimary>
        </ContainerDownloadBtn>
      )}
    </>
  )
}
