import { useState, useEffect } from 'react'
import { StructureForm } from './StructureForm'
import { SelectLabel } from '../Select/SelectLabel'
import { LabelCalendar } from '../LabelCalendar/LabelCalendar'
import { InputTypeAnswers } from '../Inputs/InputTypeAnswers'
import { useToken } from '../../hooks/useToken'
import { postInfAcademic } from '../../apis/endpointQuestions'
import { ErrorMessage } from '../ErrorsMessage/ErrorMessage'
import { keySessionStorage } from '../../config/keySessionStorage'
import { useSessionStorage } from '../../hooks/useSessionStorage'

export const FormAcademico = ({ questions = [], callBackNext }) => {
  const { infAcademic } = keySessionStorage
  const [valPerSession, handleChangeSession] = useSessionStorage(
    infAcademic,
    {}
  )
  const [valAnswers, setValAnswers] = useState({})
  const [digitError, setDigitError] = useState(false)
  const [token] = useToken()

  const handleChangeInput = ({ name, value, digitError }) => {
    setDigitError(digitError)
    handleChangeSession({ ...valPerSession, [name]: value })
    setValAnswers({ ...valAnswers, [name]: { isError: false } })
  }

  const handleChangeCalendar = ({ dateChange, index }) => {
    handleChangeSession({ ...valPerSession, [index]: dateChange })
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
    setDigitError(false)
  }

  const handleSelctChange = ({ index, value }) => {
    handleChangeSession({ ...valPerSession, [index]: value })
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
    setDigitError(false)
  }

  const getDataDefault = () => {
    let currentVal = {}
    questions.forEach((element) => {
      currentVal =
        element.value !== undefined
          ? {
              ...currentVal,
              [element.id]: element.value,
            }
          : currentVal
    })
    const cond =
      valPerSession === null
        ? { ...currentVal }
        : { ...valPerSession, ...currentVal }
    handleChangeSession({ ...cond })
  }

  const handleSendAnswers = async (ant) => {
    const quantityQuestions = Object.keys(valPerSession).length
    let currentResponse
    questions.forEach((e) => {
      currentResponse = {
        ...currentResponse,
        [e.id]: {
          isError:
            valPerSession[e?.id] === undefined || valPerSession[e?.id] === '',
        },
      }
    })
    setValAnswers(currentResponse)

    if (quantityQuestions === questions.length) {
      if (!digitError) {
        let stateOfSend = true
        for (const element in valPerSession) {
          stateOfSend =
            valPerSession[element] !== '' ||
            valPerSession[element] !== undefined
        }
        if (stateOfSend) {
          try {
            await postInfAcademic(valPerSession, token)
            // console.log(data)
            callBackNext(ant)
          } catch {
            console.log('error')
          }
        }
      }
    }
  }

  const handleBack = (ant) => callBackNext(ant)

  useEffect(getDataDefault, [])

  return (
    <StructureForm
      title='Información Academica'
      idForm={2}
      callBackNext={handleSendAnswers}
      callBackPrev={handleBack}
    >
      {questions.map((question) => {
        const res = Object.getOwnPropertyNames(valPerSession).find((e) => {
          return question.id === Number(e)
        })
        return (
          <div key={question.id}>
            {question.possible_answer === 'text' ||
            question.possible_answer === 'number' ||
            question.possible_answer === 'email' ? (
              <InputTypeAnswers
                onChange={handleChangeInput}
                type={question.possible_answer}
                title={question.survey_name}
                id={question.id}
                isErrorSubmit={valAnswers}
                valueData={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            ) : question.possible_answer === 'date' ? (
              <LabelCalendar
                question={question.survey_name}
                onChange={handleChangeCalendar}
                index={question.id}
                isErrorSubmit={valAnswers}
                value={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            ) : (
              <SelectLabel
                answers={question.possible_answer}
                index={question.id}
                title={question.survey_name}
                onChange={handleSelctChange}
                isErrorSubmit={valAnswers}
                defaultValue={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            )}
          </div>
        )
      })}
      {digitError && (
        <ErrorMessage withTimeout={false}>
          tienen uno o mas campo mal digitados
        </ErrorMessage>
      )}
    </StructureForm>
  )
}
