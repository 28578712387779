import axios, { AxiosResponse } from 'axios'
import { API_URL } from './urls'
import { configAxios } from './Authorization'

/**
 * this module is the enpoints
 * @module endpoints
 */

// get all questions
/**
 * get all the questions that have the form oriented to graduate
 * @param {String} token the string that have hidden the id and another data important
 * @returns {Promise<AxiosResponse>}
 */
export const getQuestions = (token) =>
  axios.get(`${API_URL}/graduate/survey/index`, configAxios(token))

// get answers-select
/**
 * this function get answers options that have select[html] automatized
 * @param {String} url url that get the options that include in the select[html]
 * @param {String} token the string that have hidden the id and another data important
 * @returns {Promise<AxiosResponse>}
 */
export const getAnswers = (url = '', token) => {
  const modificatedUrl = url.replace('/api/', '')
  return axios.get(`${API_URL}/${modificatedUrl}`, configAxios(token))
}

// get questions-laboral
/**
 * get the questions by category or sections that have the form-graduates
 * @param {String} token the string that have hidden the id and another data important
 * @param {String | Number} id this id is the identificator that save any sections that have
 * [labor-info]
 * @returns {Promise<AxiosResponse>}
 */
export const getQuestionsLaboral = (token, id) =>
  axios.get(
    `${API_URL}/graduate/survey/work/occupation/${id}`,
    configAxios(token)
  )

// post inf basic
/**
 * create register of answers that user-graduate in the database [section: inf-basic]
 * @param {{}} answers Object that will send to backend like answers in section inf-basic
 * @param {String} token the string that have hidden the id and another data important
 * @returns {Promise<AxiosResponse>}
 */
export const postInfBasic = (answers, token) =>
  axios.post(
    `${API_URL}/graduate/survey/basic/information`,
    answers,
    configAxios(token)
  )

// post inf academic
/**
 * create register of answers that has graduate and send to save in database [section: inf-academic]
 * @param {{}} answers Object that will send to backend like answers in section inf-Academic
 * @param {String} token the string that have hidden the id and another data inportant
 * @returns {Promise<AxiosResponse>}
 */
export const postInfAcademic = (answers, token) =>
  axios.post(
    `${API_URL}/graduate/survey/academic/information`,
    answers,
    configAxios(token)
  )

// post infLAboral
/**
 * create register of anwsers that has graduate and send to save in database [section: inf-laboral]
 * @param {{}} answers Object that will send to backend like answers in sections inf-Laboral
 * @param {String} token the string that have hidden the id and another data important
 * @returns {Promise<AxiosResponse>}
 */
export const postInfLaboral = (answers, token) =>
  axios.post(
    `${API_URL}/graduate/survey/labor/information`,
    answers,
    configAxios(token)
  )

// postPoll
/**
 * cerate register of answers that has graduate and send to save in database [section: poll]
 * @param {{}} answers Object that will send to backend like answers in sections poll
 * @param {String} token the string that have hidden the id and another data important
 * @returns {Promise<AxiosResponse>}
 */
export const postPoll = (answers, token) =>
  axios.post(`${API_URL}/graduate/survey/reply`, answers, configAxios(token))

// postAppreciation
/**
 * create register of answers that has graduate and send to save in database
 * [section: appreciation-program]
 * @param {{}} answers Object that will send to backend like answers in section appreciation-program
 * @param {String} token the string that have hidden the id and another data important
 * @returns {Promise<AxiosResponse>}
 */
export const postAppreciation = (answers, token) =>
  axios.post(
    `${API_URL}/graduate/survey/appreciation`,
    answers,
    configAxios(token)
  )
