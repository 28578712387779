import { Wrapper } from './styles'

export const ErrorMessage = () => (
  <Wrapper
    style={{
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: '#FFF',
      borderRadius: 5,
      padding: '10px 15px',
    }}
  >
    <p>
      <b>La contraseña debe tener estas caracteristicas:</b>
    </p>
    <p>🟠8 caracteres como minimo</p>
    <p>🟠1 o mas letras mayusculas al inicio</p>
    <p>🟠1 o mas letras minusculas</p>
    <p>🟠puede contener estos caracteres(,-_.+-*)</p>
  </Wrapper>
)

export const MsgPostError = ({ children }) => (
  <Wrapper
    style={{
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: '#FFF',
      borderRadius: 5,
      padding: '10px 15px',
    }}
  >
    <p style={{ textAlign: 'center' }}>{children}</p>
  </Wrapper>
)
