import styled from 'styled-components'
import { motion } from 'framer-motion'

// tamaños => 480 | 600 | 768 | 900 | 1200

// #f5f7f9
export const Header = styled.header`
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #fff;
  height: 50px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  z-index: 995;

  @media screen and (max-width: 600px) {
    height: 50px;
  }
`

export const Nav = styled.nav`
  /* position: relative; */
  align-items: center;
  display: flex;
  overflow: hidden;
`

export const Ul = styled(motion.ul)`
  display: flex;
  padding: 0;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    position: fixed;
    width: 50%;
    padding-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    height: 100%;
    background-color: #f2f2f2;
    right: -100%;
    top: 50px;
    margin: 0;
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    width: 60%;
  }
`

export const Li = styled.li`
  margin: 0 10px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;

    &:hover {
      background-color: #ccc;
    }
    & a,
    & p,
    button {
      width: 100%;
      padding: 8px;
      display: flex;
    }

    & button {
      border: none;
    }
  }
`

export const H1 = styled.h1`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: 900px) {
    font-size: 17px;
  }
  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`
export const Button = styled.button`
  display: none;
  padding: 5px;
  padding-bottom: 0;

  @media screen and (max-width: 900px) {
    display: block;
  }
`

export const P = styled.p`
  font-family: 'Poppins', sans-serif;
`
export const ButtonLogout = styled.button`
  font-size: 15px;
`
