import { useState } from 'react'
import { LogicLogin } from './LogicLogin'
import { MessageLogin, Messages } from './Messages'
import { MissCredentials } from './MissCredentials'
import { Container } from './styles'

export const ContentModal = () => {
  const [missCredential, setMissCredential] = useState(false)
  const [isMessage, setIsMessage] = useState(true)

  const returnCredential = (value) => setMissCredential(value)
  const handleHidden = () => setIsMessage(!isMessage)

  return (
    <Container>
      {missCredential && isMessage && <Messages handleHidden={handleHidden} />}
      {!missCredential && <MessageLogin handleHidden={returnCredential} />}
      {!missCredential ? (
        <LogicLogin />
      ) : (
        <MissCredentials missCredential={returnCredential} />
      )}
    </Container>
  )
}
