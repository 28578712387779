import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  margin-bottom: 25px;
  /* border: 1px solid red; */
`

export const Button = styled.button`
  display: flex;
  height: 100%;
  align-items: center;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 3px 3px 0;
  padding: 0 15px;
`

export const styleInput = {
  width: '100%',
  borderRadius: '3px 0px 0px 3px',
  height: '100%',
  padding: '0 10px',
}
