import axios, { AxiosResponse } from 'axios'
import { configAxios } from './Authorization'
import { URL_FILTER } from './urls'

/**
 * function that get data that have the select-options to filter in Academic-info
 * @param {String} option options is the endpoint to go search
 * @param {String} token token for using the authorization
 * @returns {Promise<AxiosResponse>} return the promise like AxiosResponse
 */
export const endpointSelectOptions = (option, token) =>
  axios.get(`${URL_FILTER}/${option}`, configAxios(token))
