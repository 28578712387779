import { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  opacity: 0.85;
`

const Div = styled.div`
  width: 85%;
  padding: 10px;
  background-color: red;
  border-radius: 3px;
`

const P = styled.p`
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  text-align: center;
`

export const ErrorValidation = ({ setIsError }) => {
  useEffect(() => {
    const idTimeOut = setTimeout(() => setIsError(false), 2500)
    return () => {
      clearTimeout(idTimeOut)
    }
  }, [])

  return (
    <Container>
      <Div>
        <P>Error en las crendeciales</P>
      </Div>
    </Container>
  )
}
