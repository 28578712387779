import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, useHistory } from 'react-router'
import { ProgressiveBar } from '../../components/ProgressiveBar/ProgressiveBar'
import { FormPrincipal } from '../../components/FormContent/FormPrincipal'
import { hiddenToVisible } from '../../config/animates'
import { userData } from '../../context/UserProvider'
import { useToken } from '../../hooks/useToken'
import { getQuestions } from '../../apis/endpointQuestions'
import { ThreeLoading } from '../../components/Spinners/ThreeLoading'
import { FormAcademico } from '../../components/FormContent/FormAcademico'
import { FormLaboral } from '../../components/FormContent/FormLaboral'
import { FormEncuesta } from '../../components/FormContent/FormEncuesta'
import { FormApreciacionPrograma } from '../../components/FormContent/FormApreciacionPrograma'
import { BgFormSvg2 } from '../../components/SvgComponents/BgFormSvg'
import { CardInformation } from '../../components/FormContent/CardInformation'
import {
  Container,
  ContentForm,
  Main,
  BackgroundFixed,
  ContainAbsolute,
  TextFinished,
} from './styles'

export const FormGraduates = () => {
  const [FormBasic, setFormBasic] = useState([])
  const [infAcademica, setInfAcademica] = useState([])
  const [questionsEncuestas, setQuestionsEncuestas] = useState([])
  const [questionProgram, setQuestionProgram] = useState([])
  const [idFormLaboral, setIdFormLaboral] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  // Edited
  const [idForm, setIdForm] = useState(1)
  const [token] = useToken()
  const history = useHistory()
  const { rol } = userData()
  if (rol === '') return <Redirect to='/login' />
  if (rol === 'Administrador') return <Redirect to='/admin' />

  const handleFinish = (id, idImportantLaboral) => {
    idImportantLaboral !== undefined && setIdFormLaboral(idImportantLaboral)
    setIdForm(id)
    window.scrollTo(0, 0)
  }

  const getAllQuestions = async () => {
    try {
      setIsError(false)
      const {
        data: { message },
      } = await getQuestions(token)
      const infBasic = message.filter((el) => el.id_categories === 1)
      const academico = message.filter((el) => el.id_categories === 2)
      const encuestas = message.filter((el) => el.id_categories === 7)
      const apreciationProgram = message.filter((el) => el.id_categories === 8)
      setFormBasic(infBasic)
      setInfAcademica(academico)
      setQuestionsEncuestas(encuestas)
      setQuestionProgram(apreciationProgram)
      // console.log(apreciationProgram)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      setIsError(true)
    }
  }

  useEffect(() => {
    rol !== '' && getAllQuestions()
  }, [])

  if (isError) history.push('*')

  if (isLoading)
    return (
      <Container style={{ padding: '20%' }}>
        <ThreeLoading />
      </Container>
    )

  return (
    <>
      <Helmet>
        <title>Formulario</title>
      </Helmet>
      <Main>
        <ContentForm
          initial='hidden'
          animate='visible'
          variants={hiddenToVisible}
        >
          {idForm === 1 ? (
            <FormPrincipal callBackNext={handleFinish} questions={FormBasic} />
          ) : idForm === 2 ? (
            <FormAcademico
              callBackNext={handleFinish}
              questions={infAcademica}
            />
          ) : idForm === 3 ? (
            <FormLaboral
              idFormLaboral={idFormLaboral}
              callBackNext={handleFinish}
            />
          ) : idForm === 4 ? (
            <FormEncuesta
              questions={questionsEncuestas}
              callBackNext={handleFinish}
            />
          ) : idForm === 5 ? (
            <FormApreciacionPrograma
              questions={questionProgram}
              callBackNext={handleFinish}
            />
          ) : (
            <Container>
              <TextFinished>
                Muchas gracias por diligenciar el formulario
              </TextFinished>
              <ContainAbsolute>
                <BgFormSvg2 width='100%' />
              </ContainAbsolute>
            </Container>
          )}
        </ContentForm>
      </Main>
      <ProgressiveBar idForm={idForm} />
      {/* <CardInformation currentIdForm={idForm} /> */}
      <div style={{ marginBottom: 35 }} />
    </>
  )
}
