import { useState, useEffect } from 'react'
import { useToken } from '../../hooks/useToken'
import { getInformationAdmin } from '../../apis/Admin'
import { Spinner } from '../Spinners/SpinnerLoading'
import { loaderVariants } from '../../config/animates'
import { TableInf } from './TableInf'
import { useFilterProvider } from '../../context/FilterProvider'
import {
  CardTable,
  ContentTable,
  ContainLoading,
  NotFoundError,
} from './styles'

export const LogicCardUsers = ({ id = 1, handleQuantity }) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [_, filterResult, loadingFilter, errorFilter] = useFilterProvider()
  const [isFirstSearch, setIsFirstSearch] = useState(false)
  const [token] = useToken()

  const getDataInfo = async (id) => {
    try {
      setLoading(true)
      setError(false)
      const {
        data: { message },
      } = await getInformationAdmin(id, token)
      setLoading(false)
      setUsers(message?.data || [])
      handleQuantity(message?.total || 0)
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    getDataInfo(id)
  }, [id])

  useEffect(() => {
    isFirstSearch && setUsers(filterResult?.data ? filterResult.data : [])
    Object.entries(filterResult).length === 1 && setIsFirstSearch(true)
  }, [filterResult])

  return (
    <>
      <ContentTable idCurrent={id}>
        <CardTable isScrollY={id}>
          {loading || loadingFilter ? (
            <ContainLoading>
              <Spinner variants={loaderVariants} animate='animationOne' />
            </ContainLoading>
          ) : error || errorFilter ? (
            <NotFoundError>Ha ocurrido un error inesperado</NotFoundError>
          ) : users.length > 0 ? (
            <TableInf users={users} id={id} />
          ) : (
            <NotFoundError>No se encuentran datos</NotFoundError>
          )}
        </CardTable>
      </ContentTable>
    </>
  )
}
