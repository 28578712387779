import styled from 'styled-components'
import { motion } from 'framer-motion'

// tamaños => 480 | 600 | 768 | 900 | 1200

export const CardContainer = styled(motion.div)`
  width: calc((100% - 80px) / 4);
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 3px 15px rgba(51, 51, 51, 0.2);
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: calc(100% / 2.1);
    margin: 5px 0;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 10px;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: ${({ bgCard = '#321FDB' }) => bgCard};
  color: var(--backgroundColor);
  transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  position: relative;

  z-index: 1;

  &:hover {
    background-color: ${({ bgHover = '#21158e' }) => bgHover};
    transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
  }
`

export const AnimateCircle = styled(motion.div)`
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -90px;
  right: -90px;

  z-index: -1;
`

export const TitleCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-start;

  @media screen and (max-width: 900px) {
    & svg {
      width: 20px;
    }
  }
`

export const Title = styled.h4`
  font-weight: 500;
  font-size: ${({ sizeTitle = 16 }) => sizeTitle + 'px'};
  letter-spacing: 1px;

  @media screen and (max-width: 900px) {
    letter-spacing: 0;
    font-size: 14px;
  }
`
