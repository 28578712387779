import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { StructureForm } from './StructureForm'
import { SelectLabel } from '../Select/SelectLabel'
import { InputTypeAnswers } from '../Inputs/InputTypeAnswers'
import { LabelCalendar } from '../LabelCalendar/LabelCalendar'
import { useToken } from '../../hooks/useToken'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { postInfBasic } from '../../apis/endpointQuestions'
import { ErrorMessage } from '../ErrorsMessage/ErrorMessage'
import { keySessionStorage } from '../../config/keySessionStorage'
import { LabelRadioButton } from '../RadioButtons/LabelRadioButton'
import { textPermission } from '../../config/permissionText'

export const FormPrincipal = ({ questions = [], callBackNext }) => {
  const { basicInformation } = keySessionStorage
  const navigation = useHistory()
  const [valPerSession, handleChangeSession] = useSessionStorage(
    basicInformation,
    {}
  )
  const [digitError, setDigitError] = useState(false)
  const [hiddenQuestion, setHiddenQuestion] = useState(false)
  const [idImportant, setIdImportant] = useState(3)
  const [valAnswers, setValAnswers] = useState({})
  const [token] = useToken()

  const handleChangeInput = ({ name, value, digitError }) => {
    setDigitError(digitError)
    handleChangeSession({ ...valPerSession, [name]: value })
    setValAnswers({ ...valAnswers, [name]: { isError: false } })
  }

  const handleChangeCalendar = ({ dateChange, index }) => {
    handleChangeSession({ ...valPerSession, [index]: dateChange })
  }

  const handleSelctChange = ({ index, value }) => {
    handleChangeSession({ ...valPerSession, [index]: value })
    setIdImportant(value)
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
  }

  const handleRadioButtonChange = ({ id, idQuestion }) => {
    handleChangeSession({ ...valPerSession, [idQuestion]: id === 1 })
    setValAnswers({ ...valAnswers, [idQuestion]: { isError: false } })
    setHiddenQuestion(id === 1)
  }

  const getDataDefault = () => {
    let currentVal = {}
    questions.forEach((element) => {
      currentVal =
        element.value !== undefined
          ? {
              ...currentVal,
              [element.id]: element.value,
            }
          : currentVal
    })
    const cond = { ...valPerSession, ...currentVal }
    handleChangeSession(cond)
  }

  const handleSendAnswers = async (ant) => {
    let currentResponse

    questions.forEach((e) => {
      currentResponse = {
        ...currentResponse,
        [e.id]: {
          isError:
            valPerSession[e?.id] === undefined || valPerSession[e?.id] === '',
        },
      }
    })
    let quantityQuestions = Object.keys(valPerSession).length

    if (!hiddenQuestion) {
      handleChangeSession({ ...valPerSession, 83: 4 })
      quantityQuestions = Object.keys({ ...valPerSession, 83: 4 }).length
      setIdImportant(4)
      currentResponse[83] = { isError: false }
    }
    setValAnswers(currentResponse)

    const condition = quantityQuestions === questions.length
    if (condition) {
      if (!digitError) {
        let stateOfSend = true
        for (const element in valPerSession) {
          stateOfSend =
            valPerSession[element] !== '' ||
            valPerSession[element] !== undefined
        }
        if (stateOfSend) {
          try {
            await postInfBasic(
              {
                ...valPerSession,
                83: !hiddenQuestion ? 4 : valPerSession[83],
                data_processing: true,
              },
              token
            )
            callBackNext(ant, !hiddenQuestion ? 4 : idImportant)
          } catch {
            console.log('error')
          }
        } else setDigitError(true)
      }
    }
  }

  useEffect(getDataDefault, [])
  useEffect(() => {
    if (!confirm(textPermission)) navigation.push('/')
  }, [])

  return (
    <StructureForm
      title='Información Basica'
      idForm={1}
      callBackNext={handleSendAnswers}
    >
      {questions.map((question) => {
        const res = Object.getOwnPropertyNames(valPerSession).find((e) => {
          return question.id === Number(e)
        })
        return (
          <div key={question.id}>
            {question.possible_answer === 'text' ||
            question.possible_answer === 'number' ||
            question.possible_answer === 'email' ? (
              <InputTypeAnswers
                onChange={handleChangeInput}
                type={question.possible_answer}
                title={question.survey_name}
                isErrorSubmit={valAnswers}
                id={question.id}
                valueData={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            ) : question.possible_answer === 'date' ? (
              <LabelCalendar
                onChange={handleChangeCalendar}
                question={question.survey_name}
                index={question.id}
                value={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            ) : question.possible_answer === 'radioButton' ? (
              <LabelRadioButton
                title={question.survey_name}
                idQuestion={question.id}
                isErrorSubmit={valAnswers}
                handleRadioButtonChange={handleRadioButtonChange}
              />
            ) : (
              <SelectLabel
                answers={question.possible_answer}
                index={question.id}
                title={question.survey_name}
                onChange={handleSelctChange}
                isErrorSubmit={valAnswers}
                isHiddenInfBasic={question.id === 83 && !hiddenQuestion}
                defaultValue={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            )}
          </div>
        )
      })}
      {digitError && (
        <ErrorMessage withTimeout={false}>
          tienen uno o mas campo mal digitados
        </ErrorMessage>
      )}
    </StructureForm>
  )
}
