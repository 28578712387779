import { ContainerMessage, ContentMessage, MessageText } from './styles'

export const Messages = () => {
  return (
    <ContainerMessage>
      <ContentMessage>
        <MessageText>
          Ingrese su número de identificación y haga click en el boton{' '}
          <b>recuperar.</b> Esta acción envía los datos de ingreso al correo
          electrónico registrado en nuestro sistema.
        </MessageText>
      </ContentMessage>
    </ContainerMessage>
  )
}

export const MessageLogin = ({ handleHidden }) => {
  return (
    <ContainerMessage onClick={() => handleHidden(true)}>
      <ContentMessage className='message-btn'>
        <MessageText>
          Por favor ingrese su numero de identificacion y contraseña, si no
          recuerda o no conoce los datos, <b>click aquí</b>
        </MessageText>
      </ContentMessage>
    </ContainerMessage>
  )
}
