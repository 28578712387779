import { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import styled from 'styled-components'

const Container = styled.div`
  padding: 15px 0;
`

const P = styled.p`
  margin-bottom: 5px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 37px;
  border: ${({ isError = false }) => (isError ? '2px' : '1px')} solid
    ${({ isError = false }) => (isError ? 'red' : '#ccc')};
  border-radius: 3px;
  padding: 2px 8px;
`

export const LabelCalendar = ({
  question = '',
  onChange,
  index = 0,
  value = '',
  isErrorSubmit = {},
  stylesContainer = {},
  stylesText = {},
  isFilter = false,
  name = 'default',
}) => {
  const [hidden, setHidden] = useState(true)
  const regex = /([0-9]{4})-([0-9]{2})-([0-9]{2})/
  const regexCurrentDate = regex.exec(value)
  const formatDate =
    regexCurrentDate !== null
      ? `${regexCurrentDate[3]}-${regexCurrentDate[2]}-${regexCurrentDate[1]}`
      : ''
  const [date, setDate] = useState(() => {
    return value === '' ? new Date() : new Date(value)
  })
  const [normalDate, setNormalDate] = useState(formatDate)
  const errorSubmit = isErrorSubmit[index]?.isError

  const handleHidden = () => {
    setHidden(!hidden)
  }
  const handleDate = (dateChange) => {
    const day = dateChange.getDate()
    const month = dateChange.getMonth() + 1
    const year = dateChange.getFullYear()
    const currentDate =
      month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`

    setDate(dateChange)
    setNormalDate(currentDate)
    onChange({ dateChange, index: isFilter ? name : index })
    handleHidden()
  }

  return (
    <Container style={stylesContainer}>
      <P style={stylesText}>{question}:</P>
      <Button type='button' onClick={handleHidden} isError={errorSubmit}>
        <p>{normalDate}</p>
      </Button>
      {!hidden && <Calendar value={date} onChange={handleDate} />}
    </Container>
  )
}
