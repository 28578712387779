import React from 'react'
import { Table, Th, Parrafo, Tr, Td } from './styles'

export const TableInfBasic = ({ users = [] }) => {
  return (
    <Table>
      <thead style={{ backgroundColor: '#321FDB' }}>
        <Tr>
          <Th>
            <Parrafo>Tipo de documento</Parrafo>
          </Th>
          <Th>
            <Parrafo>Número de documento</Parrafo>
          </Th>
          <Th>Nombre completo</Th>
          <Th>correo</Th>
          <Th>lugar</Th>
          <Th>
            <Parrafo>Fecha de nacimiento</Parrafo>
          </Th>
        </Tr>
      </thead>
      <tbody>
        {users.map((inf, index) => {
          return (
            <Tr key={index}>
              <Td>{inf?.document_type?.document_name}</Td>
              <Td>{inf?.certification_number}</Td>
              <Td>{inf.full_name}</Td>
              <Td>{inf.email}</Td>
              <Td>{inf?.place_shipment?.name}</Td>
              <Td>{inf.birth_date}</Td>
            </Tr>
          )
        })}
      </tbody>
    </Table>
  )
}
