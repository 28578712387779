import styled from 'styled-components'
import { motion } from 'framer-motion'

export const CardRecovery = styled(motion.div)`
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border: 1px solid #eee;
  border-radius: 7px;

  @media screen and (max-width: 900px) {
    width: 45%;
  }

  @media screen and (max-width: 768px) {
    width: 60%;
  }

  @media screen and (max-width: 600px) {
    width: 80%;
  }

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`

export const CardHead = styled.div`
  padding: 0 30px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border-bottom: 1px solid #eee; */
`

export const CardBody = styled.div`
  width: 100%;
  padding: 0 30px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  position: relative;

  & input {
    width: 100%;
    padding: 7px 10px;
    font-size: 17px;

    @media screen and (max-width: 900px) {
      font-size: 15px;
    }

    /* @media screen and (max-width: 600px) {
      font-size: 20px;
    } */
  }

  & button {
    width: 100%;
    padding: 7px;
    font-size: 15px;
  }
`

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;

  @media screen and (max-width: 900px) {
    font-size: 23px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`

export const InputWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
`

export const styleIcon = {
  position: 'absolute',
  width: 'auto',
  top: '25%',
  right: 10,
  padding: 5,
  paddingBottom: 0,
}
