// import { useState } from "react"

export const useDonwloadExcelStatic = () => {
  // const [data, setData] = useState("")

  const exportTableToExcel = (idTable, filename) => {
    const downloadLink = document.createElement('a')
    const tableSelect = document.getElementById(idTable)
    const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20')
    const dataType = 'application/vnd.ms-excel'
    const fileName = filename ? filename + '.xls' : 'excel-data.xls'

    document.body.appendChild(downloadLink)

    if (navigator.msSaveOrOpenBlob) {
      const blob = new Blob(['ufeff', tableHTML], { type: dataType })
      navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML
      downloadLink.download = fileName
      downloadLink.click()
    }
  }

  return { exportTableToExcel }
}

export const useDownloadExcelDynamic = () => {
  const getExcel = (contenidoEnBlob, nombreArchivo) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const anchor = document.createElement('a')
      anchor.href = event.target.result
      anchor.target = '_blank'
      anchor.download = nombreArchivo || 'archivo.xls'

      const clicEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })

      anchor.dispatchEvent(clicEvent)
      ;(window.URL || window.webkitURL).revokeObjectURL(anchor.href)
    }

    reader.readAsDataURL(contenidoEnBlob)
  }

  return { getExcel }
}
