import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px 0;
`

export const P = styled.p`
  margin-bottom: 5px;
`
export const colorsSelect = (isError = false) => ({
  control: (styles) => ({
    ...styles,
    borderColor: isError ? 'red' : '#CCC',
    borderWidth: isError ? 2 : 1,
    boxShadow: 'none',
    cursor: 'text',
    ':hover': {
      ...styles[':hover'],
      borderColor: isError ? 'red' : '#ccc',
    },
  }),
})
