import { useDateRegex } from '../../hooks/useDateRegex'
import { Table, Tr, Th, Td, Parrafo } from './styles'

export const TableInfAcademic = ({ users = [] }) => {
  const { normalizeDate } = useDateRegex()

  return (
    <Table>
      <thead style={{ backgroundColor: '#F9B115' }}>
        <Tr>
          <Th>#</Th>
          <Th>Programa</Th>
          <Th>
            <Parrafo>Tipo de formación</Parrafo>
          </Th>
          <Th>
            <Parrafo>Modalidad de graduación</Parrafo>
          </Th>
          <Th>
            <Parrafo>Forma de graduación</Parrafo>
          </Th>
          <Th>Universidad</Th>
          <Th>
            <Parrafo>Año de grado</Parrafo>
          </Th>
        </Tr>
      </thead>
      <tbody>
        {users.map((data, index) => {
          const { day, mouth, year } = normalizeDate(
            data?.senior_year || '2020-02-10'
          )
          return (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                <Parrafo>{data?.professional_title?.program_name}</Parrafo>
              </Td>
              <Td>{data?.type_formation?.formations_name}</Td>
              <Td>{data?.degree_modalitie?.modality_name}</Td>
              <Td>{data?.modality_name}</Td>
              <Td>{data?.university?.university_name}</Td>
              <Td>{`${day}-${mouth}-${year}`}</Td>
            </Tr>
          )
        })}
      </tbody>
    </Table>
  )
}
