import { useState } from 'react'
// import { hiddenToVisible } from "../../config/animates"
import { ContentBar, Container, BarProgress, CircleContent } from './styles'
import { CheckedSvg } from './CheckedSvg'

export const ProgressiveBar = ({ idForm = 0 }) => {
  // mejorar
  // const [percent, setPercent] = useState(0)
  // const [isHidden, setIsHidden] = useState(false)
  const data = [
    'Información Basica',
    'Información academica',
    'Información laboral',
    'Encuesta',
    'Apreciación de programa',
  ]

  return (
    <Container>
      <ContentBar>
        <BarProgress>
          {data.map((title, index) => {
            return (
              <CircleContent key={index}>
                <span>{title}</span>
                <CheckedSvg color='#229954' isHidden={idForm > index + 1} />
              </CircleContent>
            )
          })}
        </BarProgress>
      </ContentBar>
    </Container>
  )
}
