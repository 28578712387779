import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ContentTable = styled(motion.article)`
  width: ${({ idCurrent = 1 }) => (idCurrent === 5 ? '100%' : '65%')};
  padding: 10px;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

export const CardTable = styled.div`
  width: 100%;
  /* border: 1px solid #eee; */
  min-height: 200px;
  padding-bottom: 10px;
  border-radius: 3px;
  overflow-x: ${({ isScrollY = 0 }) =>
    isScrollY === 5 ? 'visible' : 'scroll'};
`

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 10px;
`

export const Tr = styled.tr`
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;

  & th {
    color: #fff;
  }
`
export const Th = styled.th`
  width: calc(100% / 4);
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  border: none;
  padding: 7.5px;
  color: #112;
`

export const Td = styled.td`
  width: calc(100% / 4);
  text-align: justify;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
  color: #122;

  &:nth-last-child(1) {
    text-align: center;
  }
`

export const Parrafo = styled.p`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const ContainLoading = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  justify-content: center;
  height: 100%;
`

export const NotFoundError = styled.p`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  color: #ccc;
`

export const ContainerDownloadBtn = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

// program appreciation

export const ContentProgram = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const CardProgram = styled.div`
  width: ${({ idCurrent = 1 }) => (idCurrent === 5 ? '49%' : '100%')};
  padding: 10px;
  margin: 10px 0;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
`

export const ParrafoProgram = styled.p`
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
  margin-bottom: 10px;
`

export const PAnswers = styled.p`
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 2;
  font-family: 'Poppins', sans-serif;
`
