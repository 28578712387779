import React from 'react'
import Select from 'react-select'
import { Label } from '../CardFilterAdmin/styles'

export const SelectFilter = React.forwardRef(
  (
    {
      name = '',
      titleLabel = '',
      options = [],
      onChange,
      loading = true,
      placeholder = '',
      isMulti = false,
      defaultValue = [],
      isClearable = false,
      ...otherProps
    },
    ref
  ) => (
    <>
      <Label htmlFor={name}>{titleLabel}:</Label>
      <Select
        ref={ref}
        options={options}
        name={name}
        isLoading={loading}
        loadingMessage='cargando...'
        onChange={onChange}
        placeholder={placeholder}
        maxMenuHeight={180}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        defaultValue={defaultValue}
        isClearable={isClearable}
        {...otherProps}
      />
    </>
  )
)
