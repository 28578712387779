import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CgKeyhole } from 'react-icons/cg'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { InputPrimary } from '../../components/Inputs/InputPrimary'
import { ButtonOutLine } from '../../components/Buttons/ButtonOutLine'
import { loaderVariants, variantsRecovery } from '../../config/animates'
import { regexPassword } from '../../config/expresions'
import { ErrorMessage, MsgPostError } from './ErrorMessage'
import { resetPassword } from '../../apis/endpoints'
import { Spinner } from '../Spinners/SpinnerLoading'
import {
  CardRecovery,
  CardHead,
  CardBody,
  Wrapper,
  H3,
  styleIcon,
  InputWrapper,
} from './styles'

const initialModel = {
  password: '',
  confirm: '',
}

export const CardRecoveryPassword = ({ token = '' }) => {
  const [credentials, setCredentials] = useState(initialModel)
  const [hidden, setHidden] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorDigit, setErrorDigit] = useState({ password: '' })
  const credentialConfirm = credentials.password !== credentials.confirm
  const navigate = useHistory()

  const styleError = (error = false) =>
    `${error ? '2px' : '1px'} solid ${error ? 'red' : '#CCC'}`

  const onChange = ({ target: { name, value } }) => {
    const resultRegex = regexPassword.test(value)
    setCredentials({ ...credentials, [name]: value })
    name === 'password' && setErrorDigit({ [name]: !resultRegex })
  }

  const handleHidden = () => setHidden(!hidden)

  const handleReset = async () => {
    if (!credentialConfirm) {
      try {
        setIsLoading(true)
        setIsError(false)
        const { status, data } = await resetPassword(
          credentials.password,
          token
        )
        setIsLoading(false)
        console.log(data)
        status === 200
          ? navigate.push('/login')
          : setErrorDigit({ password: true })
      } catch {
        setIsLoading(false)
        setIsError(true)
      }
    }
  }

  return (
    <>
      <CardRecovery initial='init' animate='start' variants={variantsRecovery}>
        <CardHead>
          <CgKeyhole size={100} color='#EA5454' />
          <H3>Recuperar contraseña</H3>
        </CardHead>
        <CardBody>
          <Wrapper>
            <InputPrimary
              type={hidden ? 'password' : 'text'}
              name='password'
              value={credentials.password}
              onChange={onChange}
              style={{ border: styleError(errorDigit.password) }}
              placeholder='Nueva contraseña'
            />
            <button onClick={handleHidden} style={styleIcon}>
              {hidden ? <FiEyeOff size={20} /> : <FiEye size={20} />}
            </button>
          </Wrapper>
          <Wrapper>
            <InputPrimary
              type='password'
              name='confirm'
              value={credentials.confirm}
              onChange={onChange}
              style={{
                border: styleError(
                  credentialConfirm && credentials.confirm !== ''
                ),
              }}
              placeholder='Repetir contraseña'
              onKeyDown={({ keyCode }) => keyCode === 13 && handleReset()}
            />
          </Wrapper>
          {isLoading && (
            <InputWrapper style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner variants={loaderVariants} animate='animationOne' />
            </InputWrapper>
          )}
          {isError && <MsgPostError>Ha ocurrido un error</MsgPostError>}
          {(errorDigit.password || credentialConfirm) && <ErrorMessage />}
          <Wrapper>
            <ButtonOutLine onClick={handleReset}>
              Recuperar contraseña
            </ButtonOutLine>
          </Wrapper>
        </CardBody>
      </CardRecovery>
    </>
  )
}
