import axios, { AxiosResponse } from 'axios'
import { API_URL } from './urls'
import { configAxios } from './Authorization'

// login
/**
 * method that have like functionality the login user
 * @param {{certification_number: Number | String, password: String}} user
 * the logs to make login with certificate_credential and password
 * @returns {Promise<AxiosResponse>}
 */
export const postLogin = (user) => axios.post(`${API_URL}/v1/auth/login`, user)

// logout
/**
 * method that have the functionality the logout session of user
 * @param {String} token the key that have all users for hidden any params and id of these
 * @returns {Promise<AxiosResponse>}
 */
export const getLogOut = (token) =>
  axios.get(`${API_URL}/v1/auth/logout`, configAxios(token))

// recovery password
/**
 * post that send the url for email that register in database to user for recovery password
 * @param {{}} document document like the certificate of identity from users
 * @returns {Promise<AxiosResponse>}
 */
export const recoveryUser = (document) =>
  axios.post(`${API_URL}/v1/auth/create`, { document })

/**
 * reset the password for recovery the account
 * @param {String} password the password that change [reset] for recovery the account
 * @param {String} token token got of email for validate the user is the correct
 * @returns {Promise<AxiosResponse>}
 */
export const resetPassword = (password, token) =>
  axios.post(`${API_URL}/v1/auth/reset`, { password, token })
