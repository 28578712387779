import { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { variantsCardUser, variantsModal } from '../../config/animates'
import {
  Card,
  H4,
  P,
  CardHead,
  CardBody,
  Badge,
  Preview,
  Button,
  Modal,
  ButtonModal,
  ContentDiv,
  HiddenDiv,
} from './styles'

// cambiar este formato
export const CardUser = ({
  timer = 0,
  rol = 'Graduado',
  hiddenPortal,
  id = 0,
  full_name = '',
  certification_number = 0,
  // email = '',
  callHandleDownload,
}) => {
  const [hidden, setHidden] = useState(true)
  const animateBtn = { rotate: hidden ? 0 : 180 }

  const handleHidden = () => setHidden(!hidden)

  const handleSeeMore = () => {
    // const { id, full_name } = props
    hiddenPortal({ id, full_name })
    handleHidden()
  }

  const handleDownload = () => {
    handleHidden()
    callHandleDownload(id)
  }

  return (
    <Card variants={variantsCardUser(timer)} initial='init' animate='start'>
      <CardHead>
        <H4>{full_name}</H4>
        <Badge>{rol}</Badge>
      </CardHead>
      <CardBody>
        <Preview>
          <P>numero de identificación: {certification_number}</P>
        </Preview>
        <ContentDiv>
          <Button animate={animateBtn} onClick={handleHidden}>
            <FiChevronDown size={30} color='gray' />
          </Button>
          <Modal
            initial='hidden'
            animate={hidden ? 'hidden' : 'visible'}
            variants={variantsModal}
          >
            <ButtonModal onClick={handleDownload}>Download</ButtonModal>
            <ButtonModal onClick={handleSeeMore}>ver mas</ButtonModal>
          </Modal>
        </ContentDiv>
      </CardBody>
      {!hidden && <HiddenDiv onClick={handleHidden} />}
    </Card>
  )
}
