import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { HomeContent } from '../../components/HomeContent/HomeContent'

const Main = styled.main`
  height: 100%;
`

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Main>
        <HomeContent />
      </Main>
    </>
  )
}
