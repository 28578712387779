import { regexDate } from '../config/expresions'

export const useDateRegex = () => {
  const normalizeDate = (date) => {
    // if (date instanceof (Date || String)) {
    const [, year, mouth, day] = regexDate.exec(date)
    const dateRegexed = `${day}-${mouth}-${year}`
    return dateRegexed
    // } else return { day: '00', mouth: '00', year: '0000' }
  }

  return { normalizeDate }
}
