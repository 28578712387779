/**
 * this is a interface that have the array to see select/option
 * @interface
 * @type {{label: String, value: Number, color: String}}
 */
const idFilterLabor = {
  label: '',
  value: 0,
  color: '',
}

/**
 * array that save id to use in section - labor-info
 * @type {Array<idFilterLabor>}
 */
export const selectLaborModel = [
  { label: 'Empresario', value: 3, color: '#505180' },
  { label: 'Empleado', value: 4, color: '#68829E' },
  { label: 'Independiente', value: 5, color: '#AEBD38' },
  { label: 'Desempleado', value: 6, color: '#598234' },
]
