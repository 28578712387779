import { useState } from 'react'
import { useDownloadPoll } from '../../hooks/useDownloadPoll'
import { useToken } from '../../hooks/useToken'
import { CardUser } from './CardUser'
import { PortalUser } from './PortalUserPoll'
import { Container } from './styles'

export const CardUsersList = ({ users = [] }) => {
  const [show, setShow] = useState(false)
  const [user, setUser] = useState({ id: 0, name: '' })
  const { handleExportExcel } = useDownloadPoll()
  const token = useToken()[0]

  const handlePortal = ({ id, full_name }) => {
    setUser({ id, name: full_name })
    setShow(!show)
  }

  const handleDownload = (id) => {
    console.log(id)
    handleExportExcel(id, null, token)
  }

  return (
    <Container>
      <PortalUser show={show} {...user} handlePortal={handlePortal} />
      {users.map(({ id, register_data_update: { graduate } }, current) => (
        <CardUser
          key={id}
          timer={current}
          {...graduate}
          callHandleDownload={handleDownload}
          hiddenPortal={handlePortal}
        />
      ))}
    </Container>
  )
}
