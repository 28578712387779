import { motion } from 'framer-motion'
import styled from 'styled-components'

// styles of card-users-list
export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  /* border: 1px solid red; */
`

// styles card-user
export const Card = styled(motion.article)`
  width: 49%;
  flex: none;
  /* height: 114px; */
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #eee;
  display: block;
  padding: 15px 20px;
`

export const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
`

export const H4 = styled.h4`
  font-weight: 500;
  font-size: 17px;
  color: var(--secundary-color);
`

export const Badge = styled.span`
  padding: 5px 7px;
  background-color: #09f;
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  font-weight: 600;
`

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
`

export const P = styled.p`
  font-size: 14px;
  color: gray;
`

export const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SeeMore = styled(motion.div)`
  width: 100%;
  border-top: 1px solid #eee;
`
export const Modal = styled(motion.div)`
  width: 120px;
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  right: 0;
  bottom: -60px;
  border-radius: 5px;
  overflow: hidden;
`
export const ButtonModal = styled.button`
  width: 100%;
  padding: 5px 0;

  &:hover {
    background-color: #f2f2f2;
  }
`

export const ContentDiv = styled.div`
  position: relative;
`

export const HiddenDiv = styled.div`
  position: absolute;
  width: 1000%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: -50%;
  background-color: transparent;
  z-index: 1;
`
