import { useState } from 'react'
import { useHover } from '../../hooks/useHover'
import {
  CardContainer,
  Button,
  TitleCard,
  Title,
  AnimateCircle,
} from './styles'
import { optionIcon as Icon } from '../../config/optionIcon'
import { ConditionIcon } from './ConditionIcon'
import { animateSequence } from '../../config/animates'

export const Card = ({
  id = 0,
  optionIcon = Icon.barChart,
  bgCard,
  bgHover,
  primaryTitle = '+3000 Encuestados',
  otherTitle = 'Tabla',
  onClick,
}) => {
  const [ref, isHover] = useHover()
  const [isTap, setIsTap] = useState()
  const handleTaps = () => setIsTap(!isTap)

  return (
    <CardContainer
      initial='hidden'
      animate='visible'
      variants={animateSequence(0.8)}
    >
      <Button
        ref={ref}
        bgCard={bgCard}
        bgHover={bgHover}
        onMouseDown={handleTaps}
        onMouseUp={handleTaps}
        onClick={() => onClick(id)}
      >
        <TitleCard>
          <ConditionIcon optionIcon={optionIcon} />
          <Title>{primaryTitle}</Title>
        </TitleCard>
        <TitleCard>
          <Title style={{ marginLeft: 10 }} sizeTitle={14}>
            {otherTitle}
          </Title>
        </TitleCard>
        <AnimateCircle animate={{ scale: isTap ? 2.5 : isHover ? 2 : 1 }} />
      </Button>
    </CardContainer>
  )
}
