import styled from 'styled-components'
import { motion } from 'framer-motion'

/* tamaños => 480 | 600 | 768 | 900 | 1200 */

export const Container = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const ContentBar = styled(motion.div)`
  position: fixed;
  padding: 24px;
  top: 100px;
  left: 5%;
`

// falta por terminar

export const BarProgress = styled.div`
  width: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ccc;
  height: 300px;
`

export const CircleContent = styled.button`
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 15px;

  & span {
    position: absolute;
    font-size: 12px;
    left: 30px;
    top: 0;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

export const AnimateSvg = styled(motion.svg)`
  position: absolute;
  z-index: 20;
  top: -45%;
  left: -25%;
`
