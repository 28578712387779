import { Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { LogicLogin } from '../../components/ModalLogin/LogicLogin'
import { userData } from '../../context/UserProvider'

const Main = styled.main`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginAdmin = () => {
  const { rol } = userData()

  if (rol !== undefined) return <Redirect to='/' />
  return (
    <>
      <Helmet>
        <title>Admin-login</title>
      </Helmet>
      <Main>
        <LogicLogin isAdmin={true} />
      </Main>
    </>
  )
}
