import { useState } from 'react'

export const useSessionStorage = (key = '', initialValue) => {
  const [value, setValue] = useState(() => {
    try {
      const item = sessionStorage.getItem(key)
      return item !== null ? JSON.parse(item) : initialValue
    } catch {
      return initialValue
    }
  })

  const handleChange = (value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
      setValue(value)
    } catch {
      console.log('error to save value in session-storage')
    }
  }

  const handleRemove = () => {
    try {
      sessionStorage.removeItem(key)
      setValue(initialValue)
    } catch {
      console.log('error to remove the data from session-storage')
    }
  }

  return [value, handleChange, handleRemove]
}
