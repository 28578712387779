import styled from 'styled-components'

export const ButtonPrime = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;

  &:hover {
    background-color: var(--secundary-color);
    color: #fff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
`

export const BtnOutLine = styled.button`
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px 8px;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;

  &:hover {
    border-color: var(--secundary-color);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
`

const getColor = (lineColor) =>
  lineColor === 'green'
    ? '#009879'
    : lineColor === 'blue'
    ? '#3399FF'
    : lineColor === 'red'
    ? '#E55353'
    : 'var(--secundary-color)'

export const BtnOutLineBg = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ lineColor = '' }) => getColor(lineColor)};
  border-radius: 5px;
  padding: 4px 7px;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;

  &:hover {
    border-color: ${({ lineColor = '' }) => getColor(lineColor)};
    color: #fff;
    background-color: ${({ lineColor = '' }) => getColor(lineColor)};
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;

    & svg {
      stroke: #fff;
    }
  }
`
