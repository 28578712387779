import { createContext, useMemo, useContext } from 'react'
import { useHistory } from 'react-router'
import { useToken } from '../hooks/useToken'
import { useUserData } from '../hooks/useUserData'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [dataUserGlobal, setDataUserGlobal, removeUserGlobal] = useUserData()
  const navigate = useHistory()
  const [_, setToken, removeToken] = useToken()

  const handleSession = useMemo(
    () => ({
      login: (token, userData) => {
        setToken(token)
        setDataUserGlobal(userData)
        navigate.push('/form-graduates')
      },
      logout: () => {
        removeToken()
        removeUserGlobal()
        navigate.push('/')
      },
    }),
    [setToken, setDataUserGlobal, removeToken, removeUserGlobal]
  )

  return (
    <UserContext.Provider value={[dataUserGlobal, handleSession]}>
      {children}
    </UserContext.Provider>
  )
}

export const userData = () => useContext(UserContext)[0]
export const useHandleSession = () => useContext(UserContext)[1]
