export function CodeThinking(props) {
  return (
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      width={779.32}
      height={579.944}
      viewBox='0 0 779.32 579.944'
      {...props}
    >
      <path
        data-name='Rectangle 62'
        fill='#e6e6e6'
        d='M.3 19.289h703.576v333.859H.3z'
      />
      <path
        data-name='Rectangle 75'
        fill='#fff'
        d='M20.419 47.918h663.339v278.549H20.419z'
      />
      <path data-name='Rectangle 80' fill='#33b776' d='M0 0h703.576v29.89H0z' />
      <circle
        data-name='Ellipse 90'
        cx={22.212}
        cy={17.576}
        r={5.54}
        fill='#fff'
      />
      <circle
        data-name='Ellipse 91'
        cx={43.24}
        cy={17.576}
        r={5.54}
        fill='#fff'
      />
      <circle
        data-name='Ellipse 92'
        cx={64.269}
        cy={17.576}
        r={5.54}
        fill='#fff'
      />
      <path fill='#e6e6e6' d='M402.655 90.268h93.423v7.022h-93.423z' />
      <path fill='#33b776' d='M402.655 110.882h144.266v7.022H402.655z' />
      <path
        fill='#e6e6e6'
        d='M404.155 130.514h117.573v7.022H404.155zM402.655 152.055h68.637v7.022h-68.637z'
      />
      <path fill='#33b776' d='M402.655 173.095h103.592v7.022H402.655z' />
      <path fill='#e6e6e6' d='M360.074 88.059h11.44v11.44h-11.44z' />
      <path fill='#33b776' d='M360.074 108.673h11.44v11.44h-11.44z' />
      <path
        fill='#e6e6e6'
        d='M360.074 128.305h11.44v11.44h-11.44zM360.074 149.846h11.44v11.44h-11.44z'
      />
      <path fill='#33b776' d='M360.074 170.887h11.44v11.44h-11.44z' />
      <path fill='#e6e6e6' d='M402.655 194.268h93.423v7.022h-93.423z' />
      <path fill='#33b776' d='M402.655 214.882h144.266v7.022H402.655z' />
      <path
        fill='#e6e6e6'
        d='M404.155 234.514h117.573v7.022H404.155zM402.655 256.055h68.637v7.022h-68.637zM402.655 277.095h103.592v7.022H402.655zM360.074 192.059h11.44v11.44h-11.44z'
      />
      <path fill='#33b776' d='M360.074 212.673h11.44v11.44h-11.44z' />
      <path
        fill='#e6e6e6'
        d='M360.074 232.305h11.44v11.44h-11.44zM360.074 253.846h11.44v11.44h-11.44zM360.074 274.887h11.44v11.44h-11.44z'
      />
      <path
        fill='#3f3d56'
        d='M107.112 249.347h93.423v7.022h-93.423zM94.612 267.593h117.573v7.022H94.612zM214.544 160.77a61.022 61.022 0 01-89.924 53.721l-.012-.012a61.004 61.004 0 1189.936-53.71z'
      />
      <circle cx={153.855} cy={141.226} r={16.583} fill='#fff' />
      <path
        d='M182.17 192.072a85.47 85.47 0 01-57.24-.222l-.011-.008 12.354-24.386h33.165z'
        fill='#fff'
      />
      <path
        fill='#ffb7b7'
        d='M656.072 567.394h-12.259l-5.833-47.288 18.095.001-.003 47.287z'
      />
      <path
        d='M659.2 579.278l-39.532-.001v-.5a15.387 15.387 0 0115.387-15.386H659.2z'
        fill='#2f2e41'
      />
      <path
        fill='#ffb7b7'
        d='M686.016 567.394h12.26l5.832-47.288-18.094.001.002 47.287z'
      />
      <path
        d='M682.889 563.392l24.144-.001a15.387 15.387 0 0115.387 15.386v.5l-39.53.001z'
        fill='#2f2e41'
      />
      <circle
        cx={844.498}
        cy={348.347}
        r={21.428}
        transform='rotate(-63.58 610.228 438.022)'
        fill='#ffb7b7'
      />
      <path
        d='M618.379 335.73s-6.767 28.196-3.383 41.73 13.533 106.012 13.533 106.012l6.767 73.307 27.067 2.255-3.383-85.712 3.383-74.435 19.173 72.18v85.968l28.706 1.127.616-111.907s2.256-87.968-12.406-99.246-16.916-13.534-16.916-13.534z'
        fill='#2f2e41'
      />
      <path
        d='M659.24 211.673l25.679 13.534 3.383 117.29s-14.661-9.022-37.217 2.256-37.217 3.384-37.217 3.384l13.533-50.751-5.639-67.668 8.066-7.32z'
        fill='#cbcbcb'
      />
      <path
        d='M629.83 220.263l-4.684 3.816s-29.323 14.661-30.45 24.812l20.3 55.262s5.639 12.405 1.127 16.916-9.022 15.79-7.894 21.429-6.767 29.322 2.255 29.322 30.45-54.134 29.323-75.562-1.908-77.556-9.977-75.995zM649.544 212.944l10.33-1.719s38.345 10.15 43.984 27.067l-15.789 39.473s11.278 72.179 7.895 72.179h-3.384s9.023 6.767 3.384 9.022-19.173 20.3-22.556 12.406S649.724 269.87 651.98 249.57s-4.439-34.5-2.436-36.626zM654.027 167.338l1.004-2.02-5.05-2.51s-5.571-9.064-15.652-6.516-14.616 4.071-14.616 4.071l-5.038 2.535 2.529 2.515-4.536 1.525 3.03 1.505-3.525 2.027 1.898 10.388s3.152-7.88 9.212-4.868 17.144-1.557 17.144-1.557l9.63 18.638s1.987-6.534 5.53-4.792c0 0 9.048-14.411-1.56-20.941z'
        fill='#2f2e41'
      />
      <path
        d='M685.396 365.237a10.056 10.056 0 017.188-13.642l4.34-35.47 14.146 12.03-6.29 31.84a10.11 10.11 0 01-19.384 5.242zM624.894 362.796a10.056 10.056 0 00-8.593-12.803l-8.078-34.81-12.79 13.463 9.631 30.992a10.11 10.11 0 0019.83 3.158z'
        fill='#ffb7b7'
      />
      <path
        d='M685.941 229.101s14.662-5.639 19.173 11.278 10.15 50.751 10.15 50.751c.28 18.814-.72 18.814 0 37.217l-3.383 16.917-19.173-2.255.836-43.065-9.858-43.776zM604.845 242.124l-6.951.743s-6.582 4.896-6.582 16.174-1.128 45.112-1.128 45.112-.64.791 4.511 29.322c2.51 13.907 6.85 20.47 6.85 20.47l14-13-1.677-33.41 4.51-18.044z'
        fill='#2f2e41'
      />
      <path
        d='M778.32 579.944h-224a1 1 0 010-2h224a1 1 0 010 2zM312.32 579.944h-119a1 1 0 010-2h119a1 1 0 010 2z'
        fill='#cbcbcb'
      />
      <path
        d='M259.405 537.407c-19.51 14.545-25.04 40.135-25.04 40.135s26.104 2.01 45.614-12.535 25.04-40.135 25.04-40.135-26.103-2.01-45.614 12.535z'
        fill='#f2f2f2'
      />
      <path
        d='M267.533 544.236c-9.377 22.456-32.862 34.028-32.862 34.028s-8.281-24.837 1.096-47.293 32.861-34.028 32.861-34.028 8.282 24.837-1.095 47.293z'
        fill='#f2f2f2'
      />
    </svg>
  )
}
