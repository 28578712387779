import { useState } from 'react'
import { exportExcelPoll } from '../apis/Admin'
import { useDownloadExcelDynamic } from './useDownloadExcel'

export const useDownloadPoll = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { getExcel } = useDownloadExcelDynamic()

  const handleExportExcel = async (id, filter, token) => {
    try {
      setLoading(true)
      setError(false)
      const { data, headers } = await exportExcelPoll(id, filter, token)
      setLoading(false)
      getExcel(data, headers['content-disposition'])
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  return { loading, error, handleExportExcel }
}
