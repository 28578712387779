import axios, { AxiosResponse } from 'axios'
import { configAxios } from './Authorization'
import { API_ADMIN } from './urls'
/**
 * this module is the enpoints
 * @module apis-endpoints
 */

/**
 * get all questions or data that have a section in the form
 * @param {Number} id this is of id that have the sections of form
 * @param {String} token this is the id that have users
 * @returns {Promise<AxiosResponse>} this is a response using axios || AxiosResponse
 */
export const getInformationAdmin = (id, token) =>
  axios.get(`${API_ADMIN}/category/question/${id}`, configAxios(token))

/**
 * get data of forms using the filter by C.C or name of user
 * @param {String} numString this is the data numeric or string that filter in backend
 * @param {Number} gender this is the id that have users
 * @param {String} token this is the id that have users
 * @returns {Promise<AxiosResponse>} this is a response using axios || AxiosResponse
 */
export const getInformationBasicAdmin = (numString = '', gender = 1, token) =>
  axios.get(
    `${API_ADMIN}/category/question/1${
      numString === '' ? '' : `/${numString}`
    }?genero=${gender}`,
    configAxios(token)
  )

/**
 * functions that filter in section academic
 * @param {{
 * modalities: String,
 * title: String,
 * formations: String ,
 * yearFrom: Date,
 * yearTo: Date
 * }} filter
 * this a object that have a modality, title-profesional
 * and formations from Academic-form => questions[sections]
 * @param {String} token id of user that can use in navigator
 * @returns {Promise<AxiosResponse>} return a Promise of type AxiosResponse
 */
export const getInfAcademicFilter = (
  { modalities, title, formations, dateFrom, dateTo },
  token
) =>
  axios.get(
    `${API_ADMIN}/category/question/2?modalities=${modalities || ''}&title=${
      title || ''
    }&formations=${formations || ''}&yearFrom=${dateFrom || ''}&yearTo=${
      dateTo || ''
    }`,
    configAxios(token)
  )

/**
 * this functions have get all forms-questions by id and return the answers [inf-labor]
 * @param {Number} id this is of id that have the sections of form
 * @param {{}} filter this is the data-filter
 * @param {String} token this is the id that have users
 * @returns {Promise<AxiosResponse>} this is a response using axios || AxiosResponse
 */
export const getInfLabor = (id, token, filter) =>
  axios.get(`${API_ADMIN}/category/question/3/${id}`, configAxios(token))

/**
 * this get the all poll of form-graduates
 * @param {Number | String} id getById filter per user specific
 * @param {{}} filter filter that have the petition PollAdmin
 * @param {String} token id of users security
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPollAdmin = (id, filter, token) =>
  axios.get(
    `${API_ADMIN}/category/question/4${id ? `/${id}` : ''}?survey=${
      filter?.questions || ''
    }&statements=${filter?.answers || ''}`,
    configAxios(token)
  )

// download excel [missing finish]
/**
 * get the export file Excel from filter or anybody
 * @param {{}} data This is the state that have all the filter that admin can do-it
 * @param {String} token this is the id that have users
 * @param {Number} id the id is a handle that can know where section is there in the filter
 * @returns {Promise<AxiosResponse>} this is a response using axios || AxiosResponse
 */
export const getExportExcel = (data, token, id) => {
  /**
   * this is url primary that don't change, like a baseUrl for using export
   * @constant
   * @type {String}
   */
  const primaryUrl = `${API_ADMIN}/export`
  /**
   * this variable is that save a url like result of switch-case
   * in this function a will return the Promise
   * @type {String}
   */
  let newUrl

  switch (id) {
    case 1: {
      newUrl = `${primaryUrl}/users${
        data.nameUser ? `/${data.nameUser}` : ''
      }?genero=${data.gender || ''}`
      break
    }
    case 2: {
      newUrl = `${primaryUrl}/academic?modalities=${
        data.modalities || ''
      }&title=${data.title || ''}&formations=${
        data.formations || ''
      }&yearFrom=${data.dateFrom || ''}&yearTo=${data.dateTo || ''}`
      break
    }
    case 3:
      newUrl = `${primaryUrl}/labor/${
        data.laborName ? `${data.laborName}` : 'information'
      }`
      break
    case 5:
      console.log('case 5')
      break
    default:
      console.log('default')
      break
  }

  return axios.get(newUrl, {
    decompress: true,
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  })
}

/**
 * export a excel of poll form-graduates
 * @param {Number} idUser this is the id from userAdmin
 * @param {{}} filter options to filter the poll form-graduates
 * @param {String} token id verificator or authorization userAdmin
 * @returns {Promise<AxiosResponse>} return Promise of type AxiosResponse
 */
export const exportExcelPoll = (idUser, filter = {}, token) =>
  axios.get(
    `${API_ADMIN}/export/survey${idUser ? `/${idUser}` : ''}?survey=${
      filter?.questions ? JSON.stringify(filter?.questions) : ''
    }&statements=${filter?.answers ? JSON.stringify(filter?.answers) : ''}`,
    {
      decompress: true,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  )
