import {
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaChartPie,
} from 'react-icons/fa'
import { FiDroplet } from 'react-icons/fi'
import { optionIcon as Icon } from '../../config/optionIcon'

export const ConditionIcon = ({ optionIcon }) => {
  let condition = 1
  const styles = {
    size: 30,
    style: {
      marginRight: 5,
    },
  }

  switch (optionIcon) {
    case Icon.barChart:
      condition = 1
      break
    case Icon.comboChart:
      condition = 2
      break
    case Icon.lineChart:
      condition = 3
      break
    case Icon.pieChart:
      condition = 4
      break
    case Icon.droplet:
      condition = 5
      break
    default:
      condition = 1
      break
  }

  return (
    <>
      {condition === 1 ? (
        <FaChartArea {...styles} />
      ) : condition === 2 ? (
        <FaChartBar {...styles} />
      ) : condition === 3 ? (
        <FaChartLine {...styles} />
      ) : condition === 4 ? (
        <FaChartPie {...styles} />
      ) : (
        <FiDroplet {...styles} />
      )}
    </>
  )
}
