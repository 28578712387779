import { useEffect, useState } from 'react'
import { StructureForm } from './StructureForm'
import { SelectLabel } from '../Select/SelectLabel'
import { InputTypeAnswers } from '../Inputs/InputTypeAnswers'
import { LabelCalendar } from '../LabelCalendar/LabelCalendar'
import { LabelRadioButton } from '../RadioButtons/LabelRadioButton'
import { useToken } from '../../hooks/useToken'
import { ErrorMessage } from '../ErrorsMessage/ErrorMessage'
import { postAppreciation } from '../../apis/endpointQuestions'
import { keySessionStorage } from '../../config/keySessionStorage'
import { useSessionStorage } from '../../hooks/useSessionStorage'

export const FormApreciacionPrograma = ({ questions = [], callBackNext }) => {
  const [digitError, setDigitError] = useState(false)
  const timeoutIdError = setTimeout(() => setDigitError(false), 2500)
  const [valAnswers, setValAnswers] = useState({})
  const [token] = useToken()
  const { appreciationProgram } = keySessionStorage
  const [valPerSession, handleChangeSession] = useSessionStorage(
    appreciationProgram,
    {}
  )

  const handleChangeInput = ({ name, value, digitError }) => {
    setDigitError(digitError)
    name === 73 || name === 74
      ? handleChangeSession({
          ...valPerSession,
          [name]: { option: value },
        })
      : handleChangeSession({ ...valPerSession, [name]: value })
    setValAnswers({ ...valAnswers, [name]: { isError: false } })
    setDigitError(false)
  }

  const handleSelctChange = ({ index, value }) => {
    handleChangeSession({ ...valPerSession, [index]: value })
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
    setDigitError(false)
  }

  const handleRadioButtonChange = ({ id, idQuestion }) => {
    handleChangeSession({
      ...valPerSession,
      [idQuestion]: id === 1,
    })
    setValAnswers({ ...valAnswers, [idQuestion]: { isError: false } })
    setDigitError(false)
  }

  const handleSubmit = async (ant) => {
    const quantity = Object.keys(valPerSession).length
    let currentResponse
    questions.forEach((e) => {
      currentResponse = {
        ...currentResponse,
        [e.id]: {
          isError:
            valPerSession[e?.id] === undefined || valPerSession[e?.id] === '',
        },
      }
    })
    setValAnswers(currentResponse)
    if (quantity === questions.length) {
      if (!digitError) {
        try {
          const data = await postAppreciation(valPerSession, token)
          callBackNext(ant)
          console.log(data)
        } catch {
          console.log('error')
        }
      }
    } else {
      setDigitError(true)
      timeoutIdError
    }
  }

  const handleBack = (ant) => callBackNext(ant)

  useEffect(() => {
    // console.log(questions)
    return () => {
      clearTimeout(timeoutIdError)
    }
  }, [])

  return (
    <StructureForm
      title='Apreciación de programa'
      callBackNext={handleSubmit}
      callBackPrev={handleBack}
      idForm={5}
    >
      {questions.map((question) => {
        const res = Object.getOwnPropertyNames(valPerSession).find((e) => {
          return question.id === Number(e)
        })
        return (
          <div key={question.id}>
            {question.possible_answer === 'text' ||
            question.possible_answer === 'number' ||
            question.possible_answer === 'email' ? (
              <InputTypeAnswers
                onChange={handleChangeInput}
                type={question.possible_answer}
                title={question.survey_name}
                id={question.id}
                valueData={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            ) : question.possible_answer === 'radioButton' ? (
              <LabelRadioButton
                hiddenDefault={question.hidden}
                title={question.survey_name}
                idPrincipal={question.idPrincipal}
                url={question.url}
                isErrorSubmit={valAnswers}
                onChangeInput={handleChangeInput}
                handleRadioButtonChange={handleRadioButtonChange}
                idQuestion={question.id}
              />
            ) : (
              <SelectLabel
                answers={question.possible_answer}
                index={question.id}
                isMulti={question.multiple_choice}
                title={question.survey_name}
                otherState={question.other_state}
                onChange={handleSelctChange}
                isErrorSubmit={valAnswers}
                defaultValue={
                  valPerSession[res] === undefined
                    ? question.value
                    : valPerSession[res]
                }
              />
            )}
          </div>
        )
      })}
      {digitError && (
        <ErrorMessage withTimeout={false}>
          tienen uno o mas campo mal digitados
        </ErrorMessage>
      )}
    </StructureForm>
  )
}
