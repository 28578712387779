export const genderModel = [
  { label: 'Masculino', value: '1' },
  { label: 'Femenino', value: '2' },
]

export const filterBasicModel = {
  nameUser: '',
  identityT: '',
}

export const filterAcademicModel = {}

export const filterLaborModel = {}

export const filterPollModel = {}

export const fitlerProgramModel = {}
