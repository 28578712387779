import styled from 'styled-components'

export const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secundary-color);
  box-sizing: border-box;
  border-top: 2px solid #021928;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const P = styled.p`
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
`
