import { motion } from 'framer-motion'
import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  margin-top: -10px;
`

const loadingContainer = {
  width: '60px',
  height: '20px',
  display: 'flex',
  justifyContent: 'space-around',
}

const loadingCircle = {
  display: 'block',
  width: '12px',
  height: '12px',
  backgroundColor: 'black',
  borderRadius: '50%',
}

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const loadingCircleVariants = {
  start: {
    y: '0%',
  },
  end: {
    y: '100%',
  },
}

export const ThreeLoading = () => {
  const loadingCircleTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: 'reverse',
    ease: 'easeInOut',
  }

  return (
    <Div>
      <motion.div
        style={loadingContainer}
        variants={loadingContainerVariants}
        initial='start'
        animate='end'
      >
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          style={loadingCircle}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
      </motion.div>
    </Div>
  )
}
