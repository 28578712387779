/**
 * @module APIS_URL'S
 */

/**
 * @constant
 * @type {String} API_URL that is the principal for use the any type rol-users
 */
// export const API_URL = 'http://192.168.40.60:8080/apiegresados/public/api'
export const API_URL = 'https://apisec.corhuila.edu.co/api'

/**
 * @constant
 * @type {String} API_URL that is only use for administration
 */
export const API_ADMIN = `${API_URL}/admin/v1`

/**
 * @constant
 * @type {String} API_URL that is the principal for use
 */
export const URL_FILTER = `${API_URL}/graduate/survey`
