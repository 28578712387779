import styled from 'styled-components'
import { motion } from 'framer-motion'

const Button = styled.button`
  display: flex;
  align-items: center;
`

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: ${({ isError = false }) => (isError ? '2px' : '1px')} solid
    ${({ isError = false }) => (isError ? 'red' : '#ccc')};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`

const CirclePadding = styled(motion.div)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

export const Radio = ({
  id,
  isTrue = false,
  title = '',
  setState,
  children,
  isErrorSubmit = false,
}) => (
  <Button onClick={() => setState({ id, isTrue: true, title })}>
    <Circle isError={isErrorSubmit}>
      <CirclePadding
        animate={{
          scale: isTrue ? 1 : 2,
          backgroundColor: isTrue
            ? 'var(--secundary-color)'
            : 'var(--backgroundColor)',
        }}
      />
    </Circle>
    {children}
  </Button>
)
