import { useState } from 'react'
import styled from 'styled-components'
import { InputPrimary } from '../Inputs/InputPrimary'
import { RadioButton } from '../RadioButtons/RadioButton'
import { SelectLabel } from '../Select/SelectLabel'

const Container = styled.div`
  padding: 15px 0;
`

const P = styled.p`
  margin-bottom: 5px;
`

export const LabelRadioButton = ({
  title,
  //   onChange,
  idQuestion = 0,
  url,
  idPrincipal = 0,
  isHiddenA = true,
  handleSelectChange,
  retornado,
  handleRadioButtonChange,
  onChangeInput,
  isErrorSubmit = {},
}) => {
  const [isHiddenSelect, setIsHiddenSelect] = useState(true)
  const [isHidden72, setIsHidden72] = useState(true)
  const errorSubmit = isErrorSubmit[idQuestion]?.isError
  const data = [
    {
      id: 1,
      title: `si ${url !== undefined ? '(De qué programa?)' : ''}`,
      isTrue: false,
    },
    { id: 2, title: 'no', isTrue: false },
  ]

  const handleChange = ({ id }) => {
    url !== undefined && id === 1
      ? setIsHiddenSelect(false)
      : setIsHiddenSelect(true)

    if (idQuestion === 31 || idQuestion === 32)
      retornado({ id: idQuestion, status: id === 1 })

    id === 1 && (idQuestion === 73 || idQuestion === 74)
      ? setIsHidden72(false)
      : setIsHidden72(true)
    // if (idQuestion === 31 && id === 2)
    handleRadioButtonChange({ id, idQuestion })
  }

  const handleChagne = ({ target: { value } }) =>
    onChangeInput({ name: idQuestion, value })

  if (idPrincipal === 31 && isHiddenA) return <></>

  return (
    <Container>
      <P>{title}:</P>
      <RadioButton
        dataPrimary={data}
        callBackButton={handleChange}
        isErrorSubmit={errorSubmit}
      />
      {!isHiddenSelect && (
        <SelectLabel
          answers='graduate/survey/professional'
          index={idQuestion}
          isErrorSubmit={errorSubmit}
          title='¿De que programa?'
          onChange={handleSelectChange}
        />
      )}

      {!isHidden72 && (
        <>
          <p style={{ marginTop: 20, marginBottom: 5 }}>¿Cual?</p>
          <InputPrimary
            onChange={handleChagne}
            style={{ width: '100%', height: 37, padding: '2px 8px' }}
          />
        </>
      )}
    </Container>
  )
}
