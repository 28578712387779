import { useState } from 'react'
import styled from 'styled-components'
import { InputPrimary } from './InputPrimary'
import { expresions } from '../../config/expresions'

const Container = styled.div`
  padding: 15px 0;
`

const P = styled.p`
  margin-bottom: 5px;
  word-break: break-all;
`

const styleInput = (isError) => ({
  width: '100%',
  height: 37,
  padding: '2px 8px',
  borderRadius: 3,
  border: `${isError ? '2px' : '1px'} solid  ${isError ? 'red' : '#ccc'}`,
})

export const InputTypeAnswers = ({
  type = 'text',
  title,
  onChange,
  id = 0,
  valueData = '',
  idPrincipal = 0,
  isHiddenA = true,
  isErrorSubmit = {},
}) => {
  const [value, setValue] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [digitError, setDigitError] = useState(false)
  const errorSubmit = isErrorSubmit[id]?.isError

  const handleChagne = ({ target: { value } }) => {
    setIsEdit(true)
    setValue(value)
    let stateError = false

    switch (type) {
      case 'text':
        stateError = !expresions.name.test(value)
        break
      case 'number':
        stateError = !expresions.number.test(value)
        break
      case 'email':
        stateError = !expresions.email.test(value)
        break
    }
    setDigitError(stateError)
    onChange({ name: id, value, digitError: stateError })
  }

  if (idPrincipal === 32 && isHiddenA) return <></>

  return (
    <Container>
      <P>
        {title}: {isEdit ? value : valueData}
      </P>
      <InputPrimary
        onChange={handleChagne}
        value={!isEdit ? valueData : value}
        style={styleInput(errorSubmit || digitError)}
      />
    </Container>
  )
}
