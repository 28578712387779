import { FiXCircle, FiCheckCircle } from 'react-icons/fi'
import { ContentProgram, CardProgram, ParrafoProgram, PAnswers } from './styles'

export const TableAppreciation = ({ idCurrent = 1, data = [] }) => (
  <ContentProgram idCurrent={idCurrent}>
    {data.map(({ id = 0, survey_name = '', valores = [], totalRegistros }) => (
      <CardProgram idCurrent={idCurrent} key={id}>
        <ParrafoProgram>{survey_name}</ParrafoProgram>
        {valores.length > 0 && (
          <div style={{ padding: 5 }}>
            <PAnswers>
              <FiCheckCircle color='#52be80' style={{ marginRight: 3 }} />
              verdaderas: {valores[1].verdadero}
            </PAnswers>
            <PAnswers>
              <FiXCircle color='#E55353' style={{ marginRight: 3 }} />
              falsas: {valores[0].falso}
            </PAnswers>
          </div>
        )}
        {totalRegistros !== undefined && (
          <PAnswers>total de registros: {totalRegistros}</PAnswers>
        )}
      </CardProgram>
    ))}
  </ContentProgram>
)
