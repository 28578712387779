import { useEffect, useState } from 'react'
import { StructureForm } from './StructureForm'
import { SelectLabel } from '../Select/SelectLabel'
import { useToken } from '../../hooks/useToken'
import { ErrorMessage } from '../ErrorsMessage/ErrorMessage'
import { postPoll } from '../../apis/endpointQuestions'
import { keySessionStorage } from '../../config/keySessionStorage'
import { useSessionStorage } from '../../hooks/useSessionStorage'

export const FormEncuesta = ({ questions = [], callBackNext }) => {
  const [digitError, setDigitError] = useState(false)
  const timeoutIdError = setTimeout(() => setDigitError(false), 2500)
  const [token] = useToken()
  const { pollKey } = keySessionStorage
  const [valAnswers, setValAnswers] = useState({})
  const [valPerSession, handleChangeSession] = useSessionStorage(pollKey, {})

  const handleSelctChange = ({ index, value }) => {
    handleChangeSession({ ...valPerSession, [index]: value })
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
    setDigitError(false)
  }

  const handleSubmit = async (ant) => {
    const quantityQuestions = Object.keys(valPerSession).length
    let currentResponse
    questions.forEach((e) => {
      currentResponse = {
        ...currentResponse,
        [e.id]: {
          isError:
            valPerSession[e?.id] === undefined || valPerSession[e?.id] === '',
        },
      }
    })
    setValAnswers(currentResponse)

    if (quantityQuestions === questions.length) {
      if (!digitError) {
        let stateOfSend = true
        for (const element in valPerSession) {
          stateOfSend =
            valPerSession[element] !== '' ||
            valPerSession[element] !== undefined
        }
        if (stateOfSend) {
          try {
            await postPoll(valPerSession, token)
            // console.log(data)
            callBackNext(ant)
          } catch {
            console.log('error')
          }
        }
      } else {
        setDigitError(true)
        timeoutIdError
      }
    }
  }

  const handleBack = (ant) => callBackNext(ant)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutIdError)
    }
  }, [])

  return (
    <StructureForm
      title='Encuesta'
      callBackNext={handleSubmit}
      callBackPrev={handleBack}
      idForm={4}
    >
      {questions.map((question) => {
        const res = Object.getOwnPropertyNames(valPerSession).find((e) => {
          return question.id === Number(e)
        })
        return (
          <div key={question.id}>
            <SelectLabel
              answers={question.possible_answer}
              index={question.id}
              title={question.survey_name}
              onChange={handleSelctChange}
              isErrorSubmit={valAnswers}
              defaultValue={
                valPerSession[res] === undefined
                  ? question.value
                  : valPerSession[res]
              }
            />
          </div>
        )
      })}
      {digitError && (
        <ErrorMessage withTimeout={false}>
          tienen uno o mas campo mal digitados
        </ErrorMessage>
      )}
    </StructureForm>
  )
}
