import { Link, useParams } from 'react-router-dom'
import { Container, Li, Ul } from './styles'

export const BreadCrumbs = () => {
  const { id } = useParams()

  return (
    <Container>
      <Ul>
        <Li>
          <Link to='/'>Inicio</Link>
        </Li>
        <Li>
          <Link to='/admin'>admin</Link>
        </Li>
        <Li>
          <Link to={`/admin/${id}`}>{id}</Link>
        </Li>
      </Ul>
    </Container>
  )
}
