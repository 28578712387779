/**
 * this module is the enpoints
 * @module apis-endpoints
 */

/**
 * this is the config to authorization with using token
 * @param {String} token this is the token user
 * @returns {object} this function return the object to set like header in request
 */
export const configAxios = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
