import { useRef } from 'react'
import { FiEyeOff, FiEye } from 'react-icons/fi'
import { animateSequence } from '../../config/animates'
import { ThreeLoading } from '../Spinners/ThreeLoading'
import { ErrorValidation } from './ErrorValidation'
import {
  Modal,
  Form,
  InputWrapper,
  LabelForm,
  InputForm,
  Button,
} from './styles'

const styleIcon = {
  position: 'absolute',
  top: 30 + 10,
  right: 10,
  padding: 5,
  paddingBottom: 0,
}

export const ModalLogin = ({
  handleSubmit,
  handleChangeInput,
  errorCedula = false,
  values = {},
  isHidden = true,
  handleHidden,
  isError = false,
  setIsError,
  isLoading = false,
}) => {
  const refInput = useRef(null)

  return (
    <Modal initial='hidden' animate='visible' variants={animateSequence(0.7)}>
      <Form>
        <InputWrapper>
          <LabelForm htmlFor='certification_number'>
            Número de identificación
          </LabelForm>
          <InputForm
            onChange={handleChangeInput}
            name='certification_number'
            value={values.certification_number}
            id='certification_number'
            placeholder='123456789'
            isError={errorCedula}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelForm htmlFor='password'>Contraseña</LabelForm>
          <InputForm
            ref={refInput}
            onChange={handleChangeInput}
            value={values.password}
            type={isHidden ? 'password' : 'text'}
            name='password'
            id='password'
            placeholder='contraseña'
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
          />
          <button onClick={handleHidden} style={styleIcon}>
            {isHidden ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </button>
        </InputWrapper>
        <InputWrapper style={{ margin: 0 }}>
          {isError && <ErrorValidation setIsError={setIsError} />}
          {isLoading && <ThreeLoading />}
          <Button onClick={handleSubmit}>Entrar</Button>
        </InputWrapper>
      </Form>
    </Modal>
  )
}
