import { useState, useEffect, useRef } from 'react'

export const useHover = () => {
  const [value, setValue] = useState(false)
  const thisRef = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const theReference = thisRef.current
    if (theReference) {
      theReference.addEventListener('mouseover', handleMouseOver)
      theReference.addEventListener('mouseout', handleMouseOut)
    }
    return () => {
      removeEventListener('mouseover', handleMouseOver)
      removeEventListener('mouseout', handleMouseOut)
    }
  }, [thisRef.current])

  return [thisRef, value]
}
