import { StructureForm } from './StructureForm'
import { SelectLabel } from '../Select/SelectLabel'
import { InputTypeAnswers } from '../Inputs/InputTypeAnswers'
import { LabelCalendar } from '../LabelCalendar/LabelCalendar'
import { LabelRadioButton } from '../RadioButtons/LabelRadioButton'
import { ErrorMessage } from '../ErrorsMessage/ErrorMessage'
import { ThreeLoading } from '../Spinners/ThreeLoading'
import { Container } from './styles'

export const PresentationLabor = ({
  handleSubmit,
  handleBack,
  loading,
  questions = [],
  valPerSession,
  handleChangeInput,
  handleChangeCalendar,
  handleSelctChange,
  handleRadioButtonChange,
  question31,
  question32,
  valAnswers,
  getQuestionHidden,
  digitError,
}) => {
  return (
    <StructureForm
      title='Información Laboral'
      idForm={3}
      callBackNext={handleSubmit}
      callBackPrev={handleBack}
    >
      {!loading ? (
        questions.map((question) => {
          const res = Object.getOwnPropertyNames(valPerSession).find((e) => {
            return question.id === Number(e)
          })
          return (
            <div key={question.id}>
              {question.possible_answer === 'text' ||
              question.possible_answer === 'number' ||
              question.possible_answer === 'email' ? (
                <InputTypeAnswers
                  onChange={handleChangeInput}
                  type={question.possible_answer}
                  title={question.survey_name}
                  hiddenDefault={question.hidden}
                  id={question.id}
                  idPrincipal={question.idPrincipal}
                  isHiddenA={question32}
                  isErrorSubmit={valAnswers}
                  valueData={
                    valPerSession[res] === undefined
                      ? question.value
                      : valPerSession[res]
                  }
                />
              ) : question.possible_answer === 'date' ? (
                <LabelCalendar
                  onChange={handleChangeCalendar}
                  question={question.survey_name}
                  index={question.id}
                  isErrorSubmit={valAnswers}
                  value={
                    valPerSession[res] === undefined
                      ? question.value
                      : valPerSession[res]
                  }
                />
              ) : question.possible_answer === 'radioButton' ? (
                <LabelRadioButton
                  hiddenDefault={question.hidden}
                  title={question.survey_name}
                  isHiddenA={question31}
                  idPrincipal={question.idPrincipal}
                  url={question.url}
                  retornado={getQuestionHidden}
                  idQuestion={question.id}
                  isErrorSubmit={valAnswers}
                  handleSelectChange={handleSelctChange}
                  handleRadioButtonChange={handleRadioButtonChange}
                />
              ) : (
                <SelectLabel
                  hiddenDefault={question.hidden}
                  answers={question.possible_answer}
                  index={question.id}
                  title={question.survey_name}
                  idPrincipal={question.idPrincipal}
                  isHiddenA={question32}
                  isMulti={question.multiple_choice}
                  onChange={handleSelctChange}
                  isErrorSubmit={valAnswers}
                  defaultValue={
                    valPerSession[res] === undefined
                      ? question.value
                      : valPerSession[res]
                  }
                />
              )}
            </div>
          )
        })
      ) : (
        <Container>
          <ThreeLoading />
        </Container>
      )}
      {digitError && (
        <ErrorMessage withTimeout={false}>
          tienen uno o mas campo mal digitados
        </ErrorMessage>
      )}
    </StructureForm>
  )
}
