import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { NotFoundIcon } from '../../components/SvgComponents/NotFoundIcon'

const Container = styled.main`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>No Found</title>
      </Helmet>
      <Container>
        <NotFoundIcon />
      </Container>
    </>
  )
}
