import { Footer as FooterStyle, P } from './styles'

export const Footer = () => {
  return (
    <FooterStyle>
      <P>
        INSTITUCIÓN DE EDUCACIÓN SUPERIOR SUJETA A INSPECCIÓN Y VIGILANCIA POR
        EL MINISTERIO DE EDUCACIÓN NACIONAL Corhuila 2021. Todos los derechos
        reservados.
      </P>
    </FooterStyle>
  )
}
