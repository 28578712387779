import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiMenu } from 'react-icons/fi'
import { Header, Nav, Ul, H1, Button, Li, P, ButtonLogout } from './styles'
import { useHandleSession, userData } from '../../context/UserProvider'
import { ButtonOutLine } from '../Buttons/ButtonOutLine'
import { getLogOut } from '../../apis/endpoints'
import { useToken } from '../../hooks/useToken'

export const Navigation = () => {
  const [hidden, setHidden] = useState(false)
  const navigate = useHistory()
  const { logout } = useHandleSession()
  const user = userData()
  const [token] = useToken()

  const handleHidden = () => setHidden(!hidden)

  const handleToNavigate = () => {
    setHidden(false)
    navigate.push('/admin')
  }

  const handleLogout = async () => {
    try {
      await getLogOut(token)
      logout()
      setHidden(false)
    } catch {
      console.log('error logout')
    }
  }

  return (
    <Header>
      <div className='title-form'>
        <Link to='/' onClick={() => setHidden(false)}>
          <H1>Soy egresado</H1>
        </Link>
      </div>
      <Nav>
        <Ul
          animate={{
            right: hidden ? 0 : '-60%',
          }}
          transition={{ ease: 'easeIn', duration: 0.8 }}
        >
          <Li>
            <Link to='/' onClick={() => setHidden(false)}>
              Inicio
            </Link>
          </Li>
          {user.full_name !== undefined ? (
            <>
              <Li>
                <P>{user.full_name}</P>
              </Li>
              {user.rol === 'Administrador' && (
                <Li>
                  <ButtonOutLine
                    style={{ fontSize: 15 }}
                    onClick={handleToNavigate}
                  >
                    {user.rol}
                  </ButtonOutLine>
                </Li>
              )}
              <Li>
                <ButtonLogout onClick={handleLogout}>
                  Cerrar sesion
                </ButtonLogout>
              </Li>
            </>
          ) : (
            <Li>
              <Link to='/login' onClick={() => setHidden(false)}>
                Iniciar sesión
              </Link>
            </Li>
          )}
        </Ul>
        <Button onClick={handleHidden}>
          <FiMenu size={20} />
        </Button>
      </Nav>
    </Header>
  )
}
