import { createPortal } from 'react-dom'
import { AnimatePresence } from 'framer-motion'
import { FiX } from 'react-icons/fi'
import {
  Modal,
  ModalContent,
  ModalRelative,
  ModalClose,
  ButtonClose,
  TitleContent,
  Title,
} from './styles'
import {
  modalContentAnimate,
  animateContainerModal,
} from '../../config/animates'

export const Portal = ({
  titleModal = '',
  showModal = false,
  children,
  onClose,
}) => {
  return createPortal(
    <AnimatePresence>
      {showModal && (
        <Modal
          variants={animateContainerModal}
          initial='hidden'
          animate='visible'
          exit='hidden'
        >
          <ModalContent
            initial='fadeIn'
            animate='fadeOut'
            exit='fadeIn'
            variants={modalContentAnimate}
          >
            <ModalRelative>
              <TitleContent>
                <Title>{titleModal}</Title>
                <ButtonClose onClick={onClose}>
                  <FiX size={25} />
                </ButtonClose>
              </TitleContent>
              {children}
            </ModalRelative>
          </ModalContent>
          <ModalClose onClick={onClose} />
        </Modal>
      )}
    </AnimatePresence>,
    document.getElementById('portal')
  )
}
