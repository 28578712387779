import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Navigation } from './components/Navigation/Navigation'
import { Footer } from './components/Footer/Footer'
import { Home } from './pages/Home/Home'
import { Login } from './pages/Login/Login'
import { FormGraduates } from './pages/FormGraduates/FormGraduates'
import { PanelAdmin } from './pages/Admin/PanelAdmin'
import { LoginAdmin } from './pages/Admin/LoginAdmin'
import { NotFound } from './pages/NotFound/NotFound'
import { UserProvider } from './context/UserProvider'
import './App.css'
import { Users } from './pages/Admin/Users'
import { RecoveryAccount } from './pages/RecoveryAccount/RecoveryAccount'

function App() {
  return (
    <HelmetProvider>
      <Router>
        <UserProvider>
          <Navigation />
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/form-graduates'>
              <FormGraduates />
            </Route>
            <Route exact path='/admin/login'>
              <LoginAdmin />
            </Route>
            <Route exact path='/admin'>
              <PanelAdmin />
            </Route>
            <Route path='/admin/:id'>
              <Users />
            </Route>
            <Route path='/recovery/account/:token'>
              <RecoveryAccount />
            </Route>
            <Route exact path='*'>
              <NotFound />
            </Route>
          </Switch>
          <Footer />
        </UserProvider>
      </Router>
    </HelmetProvider>
  )
}

export default App
