import { useState, useEffect } from 'react'
import { useFilterProvider } from '../../context/FilterProvider'
import { useDateRegex } from '../../hooks/useDateRegex'
import { Parrafo, Table, Td, Th, Tr } from './styles'

export const TableInfLabor = ({ users = [] }) => {
  const [filterData] = useFilterProvider()
  const { normalizeDate } = useDateRegex()
  const [filterId, setFilterId] = useState(0)

  const handleDateRegex = () => normalizeDate('' || '2020-02-10')

  useEffect(() => {
    setFilterId(filterData?.laborId || 0)
  }, [filterData?.laborId])

  return (
    <Table>
      <thead style={{ backgroundColor: '#E55353' }}>
        <Tr>
          <Th>#</Th>
          {filterId === 0 ? (
            <>
              <Th>Nombre completo</Th>
              <Th>Ocupación</Th>
              <Th>
                <Parrafo>Documento de identidad</Parrafo>
              </Th>
              <Th>
                <Parrafo>Numero telefonico</Parrafo>
              </Th>
            </>
          ) : filterId === 3 ? (
            <>
              <Th>
                <Parrafo>Nombre de la compañia</Parrafo>
              </Th>
              <Th>Empresario</Th>
              <Th>
                <Parrafo>Numero telefonico</Parrafo>
              </Th>
              <Th>
                <Parrafo>correo electronico</Parrafo>
              </Th>
              <Th>
                <Parrafo>Numero telefonico de la compañia</Parrafo>
              </Th>
              <Th>
                <Parrafo>Cantidad de ingresos</Parrafo>
              </Th>
            </>
          ) : filterId === 4 ? (
            <>
              <Th>
                <Parrafo>Nombre de la compañia</Parrafo>
              </Th>
              <Th>
                <Parrafo>Sector economico</Parrafo>
              </Th>
              <Th>
                <Parrafo>Cargo del empleado</Parrafo>
              </Th>
              <Th>
                <Parrafo>Salario del empleado</Parrafo>
              </Th>
            </>
          ) : filterId === 5 ? (
            <>
              <Th>
                <Parrafo>Nombre completo</Parrafo>
              </Th>
              <Th>
                <Parrafo>Documento de identidad</Parrafo>
              </Th>
              <Th>
                <Parrafo>Numero telefonico</Parrafo>
              </Th>
              <Th>
                <Parrafo>fecha de creación del registro</Parrafo>
              </Th>
            </>
          ) : (
            <>
              <Th>
                <Parrafo>Bolsa de empleo</Parrafo>
              </Th>
              <Th>
                <Parrafo>Tiempo desempleado</Parrafo>
              </Th>
              <Th>
                <Parrafo>Ultimo registro</Parrafo>
              </Th>
            </>
          )}
        </Tr>
      </thead>
      <tbody>
        {users.map(
          (
            {
              occupation = [],
              register_update_data = { graduate: {} },
              employment_situation = {
                register_update_data: { graduate: {}, created_at: new Date() },
              },
              company_name = '',
              company_phone_number = '',
              salarie = { amount_income: '' },
              media = { name_media: '' },
              economic_sector = { industry_name: '' },
              employee_charges = { job_title: '' },
              months_unemployeds = { month_range: '' },
            },
            index
          ) => (
            <Tr key={index}>
              {filterId === 0 ? (
                <>
                  <Td>{index + 1}</Td>
                  <Td>{register_update_data?.graduate?.full_name}</Td>
                  <Td>{occupation[0]?.occupation_name}</Td>
                  <Td>
                    {register_update_data?.graduate?.certification_number}
                  </Td>
                  <Td>{register_update_data?.graduate?.cell_phone_number}</Td>
                </>
              ) : filterId === 3 ? (
                <>
                  <Td>{index + 1}</Td>
                  <Td>{company_name}</Td>
                  <Td>
                    {
                      employment_situation?.register_update_data?.graduate
                        ?.full_name
                    }
                  </Td>
                  <Td>
                    {
                      employment_situation?.register_update_data?.graduate
                        ?.cell_phone_number
                    }
                  </Td>
                  <Td>
                    {
                      employment_situation?.register_update_data?.graduate
                        ?.email
                    }
                  </Td>
                  <Td>{company_phone_number}</Td>
                  <Td>{salarie?.amount_income}</Td>
                </>
              ) : filterId === 4 ? (
                <>
                  <Td>{index + 1}</Td>
                  <Td>{company_name}</Td>
                  <Td>{economic_sector?.industry_name}</Td>
                  <Td>{employee_charges?.job_title}</Td>
                  <Td>{salarie.amount_income}</Td>
                </>
              ) : filterId === 5 ? (
                <>
                  <Td>{index + 1}</Td>
                  <Td>
                    {
                      employment_situation?.register_update_data?.graduate
                        ?.full_name
                    }
                  </Td>
                  <Td>
                    {
                      employment_situation?.register_update_data?.graduate
                        ?.certification_number
                    }
                  </Td>
                  <Td>
                    {
                      employment_situation?.register_update_data?.graduate
                        ?.cell_phone_number
                    }
                  </Td>
                  <Td>
                    {handleDateRegex(
                      employment_situation?.register_update_data?.created_at
                    )}
                  </Td>
                </>
              ) : (
                <>
                  <Td>{index + 1}</Td>
                  <Td>{media?.name_media}</Td>
                  <Td>{months_unemployeds?.month_range}</Td>
                  <Td>
                    {handleDateRegex(
                      employment_situation?.register_update_data?.created_at
                    )}
                  </Td>
                </>
              )}
            </Tr>
          )
        )}
      </tbody>
    </Table>
  )
}
