import { Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router'
import { ContentModal } from '../../components/ModalLogin/ContentModal'
import { userData } from '../../context/UserProvider'

export const Login = () => {
  const { rol } = userData()

  if (rol !== undefined) return <Redirect to='/' />

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <main style={{ height: '100%' }}>
        <ContentModal />
      </main>
    </>
  )
}
