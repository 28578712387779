import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 600px;
  /* background-color: #f3f6f9; */
  align-items: center;
  justify-content: center;
`
