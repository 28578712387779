import React, { useState, useEffect, useContext } from 'react'
import { getExportExcel } from '../apis/Admin'
import { useCaseFilter } from '../hooks/useCaseFilter'
import { useDownloadExcelDynamic } from '../hooks/useDownloadExcel'
import { useToken } from '../hooks/useToken'

const FilterContext = React.createContext()

export const FitlerProvider = ({ idCurrent = 1, children }) => {
  const [filterData, setFilterData] = useState({})
  const [downloading, setDownloading] = useState(false)
  const [errorDown, setErrorDown] = useState(false)
  const { dataResult, error, loading, switchCaseGetting } = useCaseFilter()
  const { getExcel } = useDownloadExcelDynamic()
  const [token] = useToken()

  const handleChange = ({ target: { name, value } }) =>
    setFilterData({ ...filterData, [name]: value })

  const handleSelectChange = (change, other) => {
    if (change && other) {
      const { value } = change
      const { name } = other
      setFilterData({ ...filterData, [name]: value })
    }
  }

  const handleResetFilter = () => setFilterData({})

  const handleSubmit = () => switchCaseGetting(filterData, token, idCurrent)

  const handleSubmitLabor = (laborId, { name, value }, { nameId, id }) => {
    // look to implement the export excel in labor-info
    switchCaseGetting(filterData, token, 3, laborId)
    setFilterData({ ...filterData, [name]: value, [nameId]: id })
  }

  const handleDownload = async () => {
    try {
      setErrorDown(false)
      setDownloading(true)
      const { headers, data } = await getExportExcel(
        filterData,
        token,
        idCurrent
      )
      setDownloading(false)
      getExcel(data, headers['content-disposition'])
    } catch {
      setDownloading(false)
      setErrorDown(true)
    }
  }

  useEffect(() => {}, [])

  return (
    <FilterContext.Provider
      value={[
        filterData,
        dataResult,
        loading,
        error,
        handleChange,
        handleSelectChange,
        handleResetFilter,
        handleSubmit,
        handleDownload,
        downloading,
        errorDown,
        handleSubmitLabor,
      ]}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterProvider = () => useContext(FilterContext)

// const useHandleChange = () => useFilterProvider()[4]
// const useHandleSelectChange = () => useFilterProvider()[5]
// const useHandleResetFilter = () => useFilterProvider()[6]
// const useHandleSubmit = () => useFilterProvider()[7]
const useDownloadFilter = () => useFilterProvider()[8]
const useRequestDownload = () => {
  const loadingDownload = useFilterProvider()[9]
  const errorDownload = useFilterProvider()[10]
  return [loadingDownload, errorDownload]
}

const useSubmitLabor = () => useFilterProvider()[11]

export { useDownloadFilter, useRequestDownload, useSubmitLabor }
