import { useEffect, useState } from 'react'
import { useToken } from '../../hooks/useToken'
import {
  getQuestionsLaboral,
  postInfLaboral,
} from '../../apis/endpointQuestions'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { keySessionStorage } from '../../config/keySessionStorage'
import { PresentationLabor } from './PresentationLabor'

// refactorizar codigo
// revisar memoria y usar useMemo() || memo()
export const FormLaboral = ({ callBackNext, idFormLaboral = 1 }) => {
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [digitError, setDigitError] = useState(false)
  const [question31, setQuestion31] = useState(true)
  const [question32, setQuestion32] = useState(true)
  const [token] = useToken()
  const { infLaboral, basicInformation } = keySessionStorage
  const [valPerSession, handleChangeSession] = useSessionStorage(infLaboral, {})
  const [valPerSession2] = useSessionStorage(basicInformation, {})
  const [valAnswers, setValAnswers] = useState({})

  const handleChangeInput = ({ name, value, digitError }) => {
    setDigitError(digitError)
    handleChangeSession({ ...valPerSession, [name]: value })
    setValAnswers({ ...valAnswers, [name]: { isError: false } })
  }

  const handleChangeCalendar = ({ dateChange, index }) => {
    handleChangeSession({ ...valPerSession, [index]: dateChange })
    setDigitError(false)
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
  }

  const handleSelctChange = ({ index, value }) => {
    index === 31
      ? handleChangeSession({
          ...valPerSession,
          [index]: { option: value },
        })
      : handleChangeSession({ ...valPerSession, [index]: value })
    setDigitError(false)
    setValAnswers({ ...valAnswers, [index]: { isError: false } })
  }

  const handleRadioButtonChange = ({ id, idQuestion }) => {
    handleChangeSession({
      ...valPerSession,
      [idQuestion]: id === 1,
    })
    setDigitError(false)
    setValAnswers({ ...valAnswers, [idQuestion]: { isError: false } })
  }

  const getQuestionHidden = ({ id, status }) => {
    id === 31 && setQuestion31(!status)
    id === 31 && !status && setQuestion32(true)
    id === 32 && setQuestion32(!status)
  }

  const getQuestions = async () => {
    try {
      setError(false)
      const {
        data: { message },
      } = await getQuestionsLaboral(token, valPerSession2[83] + 2)
      setQuestions(message)
      setLoading(false)
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  const handleSubmit = async (ant) => {
    const quantity = Object.keys(valPerSession).length
    let isCorrect = false
    let currentResponse
    questions.forEach((e) => {
      currentResponse = {
        ...currentResponse,
        [e.id]: {
          isError:
            valPerSession[e?.id] === undefined ||
            valPerSession[e?.id] === '' ||
            ((e?.id === 31 || e?.id === 32) && valPerSession[e?.id] === true),
        },
      }
      if (
        valPerSession[e?.id] === undefined ||
        valPerSession[e?.id] === '' ||
        ((e.id === 31 || e.id === 32) && valPerSession[e?.id] === true)
      )
        setDigitError(true)
    })
    setValAnswers(currentResponse)
    isCorrect = false

    if (idFormLaboral === 1 && quantity >= 8) {
      if (valPerSession[31] === false || valPerSession[31] !== true) {
        isCorrect = true
        setDigitError(false)
      } else if (valPerSession[31] === true) isCorrect = false
      else {
        if (valPerSession[32] === false || valPerSession[32] !== true) {
          isCorrect = true
          setDigitError(false)
        } else isCorrect = false
      }
    } else if (idFormLaboral >= 2 && quantity === questions.length) {
      isCorrect = true
      setDigitError(false)
    }

    if (isCorrect && !digitError) {
      try {
        const result = {
          Idoccupation: valPerSession2[83],
          ...valPerSession,
        }
        await postInfLaboral(result, token)
        handleChangeSession({})
        callBackNext(ant)
      } catch {
        console.log('error')
      }
    }
  }

  const handleBack = (ant) => callBackNext(ant)

  useEffect(getQuestions, [])
  // if (error)

  return (
    <PresentationLabor
      handleSubmit={handleSubmit}
      handleBack={handleBack}
      loading={loading}
      questions={questions}
      question31={question31}
      question32={question32}
      valPerSession={valPerSession}
      handleChangeCalendar={handleChangeCalendar}
      handleChangeInput={handleChangeInput}
      handleRadioButtonChange={handleRadioButtonChange}
      handleSelctChange={handleSelctChange}
      getQuestionHidden={getQuestionHidden}
      digitError={digitError}
      valAnswers={valAnswers}
    />
  )
}
