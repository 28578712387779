import { useHistory } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { CodeThinking } from '../SvgComponents/CodeThinking'
import {
  Container,
  ContentModal,
  ModalHome,
  PTitle,
  PText,
  Button,
  BackgroundColor,
  ContentImg,
} from './styles'
import { animateSequence } from '../../config/animates'
import { userData } from '../../context/UserProvider'

export const HomeContent = () => {
  const history = useHistory()
  const user = userData()

  const handleLink = () =>
    history.push(user.full_name === undefined ? '/login' : '/form-graduates')

  return (
    <Container>
      <ContentModal>
        <ModalHome
          initial='hidden'
          animate='visible'
          variants={animateSequence(1)}
        >
          <PTitle>ACTUALIZACIÓN DE DATOS</PTitle>
          <PText>
            La Corporación Universitaria del Huila - CORHUILA adelanta una
            campaña para que sus egresados actualicen su información personal y
            laboral, disponiendo para ello el siguiente aplicativo web.
          </PText>
          <PText>
            Nuestro objetivo es fortalecer los lazos y propiciar una relación
            perdurable en el tiempo. Sea esta la oportunidad para agradecerle su
            continuidad con ésta su Alma Máter y la participación activa en este
            proceso de revisión y actualización de datos.
          </PText>
          <center>
            <Button onClick={handleLink}>
              Iniciar
              <FiChevronRight size={22} />
            </Button>
          </center>
        </ModalHome>
      </ContentModal>
      <BackgroundColor />
      <ContentImg
        initial='hidden'
        animate='visible'
        variants={animateSequence(0.5)}
      >
        <CodeThinking width='100%' />
      </ContentImg>
    </Container>
  )
}
