import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router-dom'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { ContentCard } from '../../components/Card/ContentCard'
import { userData } from '../../context/UserProvider'
import { LogicCardUsers } from '../../components/TableInfAll/LogicCardUsers'
import { Main, Div, ContainFilterTable } from './styles'
import { FitlerProvider } from '../../context/FilterProvider'
import { ContentFilterDiv } from '../../components/CardFilterAdmin/ContentFilterDiv'

export const PanelAdmin = () => {
  const { rol } = userData()
  const [idCurrent, setIdCurrent] = useState(1)
  const [quantity, setQuantity] = useState(0)

  const handleSearchById = (id) => setIdCurrent(id)
  const handleQuantity = (quantity) => setQuantity(quantity)

  if (rol !== 'Administrador') return <Redirect to='/' />

  return (
    <>
      <Helmet>
        <title>Panel administrativo</title>
      </Helmet>
      <Main>
        <Div>
          <BreadCrumbs />
        </Div>
        <FitlerProvider idCurrent={idCurrent}>
          <ContentCard handleClick={handleSearchById} />
          <ContainFilterTable>
            <ContentFilterDiv idCurrent={idCurrent} quantity={quantity} />
            <LogicCardUsers id={idCurrent} handleQuantity={handleQuantity} />
          </ContainFilterTable>
        </FitlerProvider>
      </Main>
    </>
  )
}
