import styled from 'styled-components'

export const CardInformation = ({ currentIdForm = 1 }) => {
  return (
    <Container>
      {currentIdForm < 6 && (
        <Card>
          <Text>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi
            ipsum repudiandae maxime sapiente necessitatibus perspiciatis quos
            labore, impedit quaerat voluptatem, nulla repellat.
          </Text>
        </Card>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: relative;

  @media screen and (max-width: 900px) {
    display: none;
  }
`
const Card = styled.div`
  width: 20%;
  top: 40%;
  right: 3%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 3px;
`
const Text = styled.p`
  color: #fff;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
`
