import styled from 'styled-components'
import { motion } from 'framer-motion'

/* tamaños => 480 | 600 | 768 | 900 | 1200 */

export const Main = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  min-height: 850px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
`

export const ContentForm = styled(motion.div)`
  width: 50%;
  border: 1px solid #ccc;
  padding: 30px;
  border-radius: 10px;

  @media screen and (max-width: 900px) {
    width: 70%;
  }

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-top: 1%;
  position: relative;
  background-color: transparent;
  align-items: flex-start;
  justify-content: center;
`

export const ContainAbsolute = styled.div`
  position: absolute;
  width: 100%;
`

export const TextFinished = styled.p`
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1;
  font-weight: 500;
`
