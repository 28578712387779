export const hiddenToVisible = {
  hidden: {
    opacity: 0,
    scale: 1.1,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 1 },
  },
}

export const animateSequence = (duration) => ({
  ...hiddenToVisible,
  visible: { ...hiddenToVisible.visible, transition: { duration } },
})

// animates Modal

export const animateContainerModal = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
}

export const modalContentAnimate = {
  fadeIn: {
    top: -1000,
    transition: { duration: 0.5 },
  },
  fadeOut: {
    top: 0,
    transition: { duration: 0.3 },
  },
}

// custom-animates

export const opacityAnimate = {
  fadeIn: { opacity: 0, y: -100 },
  fadeOut: { opacity: 1, y: 0, transition: { delay: 1, duration: 1 } },
}

export const scaleAndDelay = (duration = 1, delay = 1) => ({
  fadeIn: { scale: 1.1, opacity: 0 },
  fadeOut: { scale: 1, opacity: 1, transition: { duration, delay } },
})

// animate transition

export const variantsTransition = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
}

// ModalEdit Animate

export const variantsModal = {
  hidden: {
    y: -60,
    width: 0,
    height: 0,
    borderColor: '#FFF',
    zIndex: 0,
    transition: { duration: 0.2 },
  },
  visible: {
    y: 0,
    width: 120,
    height: 64,
    borderColor: '#CCC',
    zIndex: 2,
    transition: { duration: 0.2 },
  },
}

export const loaderVariants = {
  animationOne: {
    x: [-20, 0, 20, 0, -20],
    y: [20, -10, 20, -10, 20],
    transition: {
      x: {
        repeat: Infinity,
        duration: 2,
      },
      y: {
        repeat: Infinity,
        duration: 2,
        ease: 'easeInOut',
      },
    },
  },
}

export const variantsRecovery = {
  init: {
    scale: 0,
    opacity: 0,
  },
  start: {
    scale: 1,
    opacity: 1,
    transition: { duration: 0.5 },
  },
}

export const variantsCardUser = (delay) => ({
  init: {
    y: -60,
    opacity: 0,
  },
  start: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      delay: delay / 2,
    },
  },
})
