import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { InputSearch } from '../../components/Search/InputSearch'
import { CardUsersList } from '../../components/CardUsers/CardUsersList'
import { Paginate } from '../../components/Paginate/Paginate'
import { getPaginateUsers, getUsers } from '../../apis/usersAdmin'
import { useToken } from '../../hooks/useToken'
import { SpinnerLoading } from '../../components/Spinners/SpinnerLoading'
import { modelPaginate } from '../../models/modelPaginate'
import { ContentUsers, Div, Main, P } from './styles'
import { FilterPoll } from '../../components/FilterPoll'
import { getPollAdmin } from '../../apis/Admin'

export const Users = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setIsError] = useState(false)
  const [errorSearch, setErrorSearch] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [paginateState, setPaginateState] = useState(modelPaginate)
  const [token] = useToken()

  // make the context to filter poll from form-graduates

  const handleGetAllUsers = async () => {
    try {
      setIsError(false)
      const {
        data: { message },
      } = await getPollAdmin(null, null, token)
      setLoading(false)
      console.log(message.data)
      setPaginateState({
        prev: message.current_page - 1,
        current: message.current_page,
        next: message.current_page + 1,
        last: message.last_page,
      })
      setUsers(message.data)
      setQuantity(message.total)
    } catch {
      setLoading(false)
      setIsError(true)
    }
  }

  const handleSearch = async (value) => {
    try {
      const result = value.replace(/ /g, '-')
      setErrorSearch(false)
      setLoading(true)
      const { data } = await getUsers(token, result.toLowerCase())
      setLoading(false)
      console.log(data)
    } catch {
      setLoading(false)
      setErrorSearch(true)
    }
  }

  const handleNavigate = async (res) => {
    try {
      setLoading(true)
      const {
        data: { message },
      } = await getPaginateUsers(res, token)
      setLoading(false)
      setUsers(message.data)
      setPaginateState({
        prev: message.current_page - 1,
        current: message.current_page,
        next: message.current_page + 1,
        last: message.last_page,
      })
    } catch {
      console.log('error')
    }
  }

  useEffect(handleGetAllUsers, [])

  return (
    <>
      <Helmet>
        <title>List users</title>
      </Helmet>
      <Main>
        <Div>
          <BreadCrumbs />
        </Div>
        {/* <InputSearch onSubmit={handleSearch} /> */}
        <FilterPoll />
        <ContentUsers>
          {error && <ErrorSearch text='Ha ocurrido un error inesperado' />}
          {errorSearch ? (
            <ErrorSearch />
          ) : loading ? (
            <SpinnerLoading />
          ) : (
            <>
              <P>total: {quantity}</P>
              <CardUsersList users={users} />
              <Paginate {...paginateState} navigatePage={handleNavigate} />
            </>
          )}
        </ContentUsers>
      </Main>
    </>
  )
}
const textDefault = 'No se encuentra ningun usuario con estos datos'
const ErrorSearch = ({ text = textDefault }) => (
  <div style={{ display: 'flex', justifyContent: 'center', minHeight: 500 }}>
    <h4 style={{ fontWeight: 500, color: 'gray' }}>{text}</h4>
  </div>
)
