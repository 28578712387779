import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 10px 30px;
`

export const ContentPaginate = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fefefe;
  padding: 5px 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(1, 2, 2, 0.4); */
`

export const ButtonNumbers = styled.button`
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 3px;
  color: gray;
  font-weight: 500;
  margin: 0 1.5px;

  &:hover {
    background-color: #09f;
    color: #fff;
  }

  &.active {
    background-color: #09f;
    color: #fff;
  }

  &:disabled {
    background-color: transparent;
    color: gray;
    cursor: auto;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 0 1.5px;

  &:hover {
    background-color: #eee;
  }
`
