import { motion } from 'framer-motion'
import styled from 'styled-components'

// tamaños => 480 | 600 | 768 | 900 | 1200

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: 768px) {
    justify-content: center;
    padding-top: 0;
  }
`

export const ContentModal = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 500px;
  }
`

export const ModalHome = styled(motion.section)`
  width: 480px;
  padding: 30px;
  border-radius: 4px;
  /* border: 1px solid #122; */
  color: var(--backgroundColor);
  background-color: #fff;
  /* will-change: transform; */
  box-shadow: 0 3px 15px rgba(51, 51, 51, 0.2);
`

export const ContentImg = styled(motion.div)`
  width: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const BackgroundColor = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #eee;
  clip-path: polygon(0% 0%, 70% 0%, 50% 100%, 0% 100%);
  z-index: -1;

  @media screen and (max-width: 768px) {
    clip-path: polygon(0% 0%, 95% 0%, 50% 100%, 0% 100%);
  }

  @media screen and (max-width: 600px) {
    clip-path: polygon(0% 0%, 95% 0%, 60% 100%, 0% 100%);
  }

  @media screen and (max-width: 480px) {
    clip-path: none;
  }
`

export const PTitle = styled.p`
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 19px;
  margin-bottom: 5px;
  color: #122;
`

export const PText = styled.p`
  text-align: justify;
  font-size: 16px;
  color: #122;
`

export const Button = styled.button`
  border: 1px solid #122;
  padding: 5px 20px;
  color: #122;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;

  &:hover {
    color: var(--backgroundColor);
    background-color: var(--secundary-color);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
  }
`
