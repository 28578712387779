import styled from 'styled-components'

/* tamaños => 480 | 600 | 768 | 900 | 1200 */

export const Main = styled.main`
  width: 100%;

  @media screen and (max-width: 480px) {
    padding-top: 10%;
  }
`
export const Div = styled.div`
  display: flex;
  padding-top: 5%;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
`
