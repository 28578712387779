import { useState, useEffect } from 'react'
import Select from 'react-select/creatable/'
import { components } from 'react-select'
import { getAnswers } from '../../apis/endpointQuestions'
import { useToken } from '../../hooks/useToken'
import {
  newValues,
  primaryCondMaxId,
  secundConditionalMax,
} from './modificatedArray'
import { Container, P, colorsSelect } from './styles'
import { InputPrimary } from '../Inputs/InputPrimary'

export const SelectLabel = ({
  title = '',
  answers = '',
  onChange,
  isMulti = false,
  idPrincipal = 0,
  isHiddenA = true,
  isHiddenInfBasic = false,
  defaultValue = 0,
  index = 0,
  otherState,
  isErrorSubmit = {},
}) => {
  const [result, setResult] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [hiddenMore, setHiddenMore] = useState(false)
  const [valueSelect, setValueSelect] = useState([])
  const errorSubmit = isErrorSubmit[index]?.isError
  const [token] = useToken()

  const getAnswersRequest = async () => {
    try {
      const {
        data: { message = [] },
      } = await getAnswers(answers, token)
      const mod = newValues(message)
      setLoading(false)
      setOptions(
        index !== 83 ? [...mod] : [...mod].filter((e) => e.value !== 4)
      )
      if (defaultValue !== undefined && defaultValue !== 0) {
        const result = mod.find((e) => e.value === defaultValue)
        handleResultInitial(result)
      }
    } catch {
      console.log('error request Select')
    }
  }

  const handleResultInitial = (val) =>
    val.label !== undefined && setResult(val.label)

  const handleResult = (value) => {
    if (value.label !== undefined) {
      setResult(value.label)
      if (value.label === 'OTRA' || value.label === 'Otro') {
        setHiddenMore(true)
        onChange({ value: { option: value.val }, index })
      } else {
        setHiddenMore(false)
        onChange({ ...value, index })
      }
    } else onChange({ value, index })

    if (otherState && isMulti) {
      const getOther = value?.find((e) => e.label === 'Otro')
      setHiddenMore(getOther !== undefined)
      setValueSelect(value)
      // onChange({ value: { option: value.val }, index })
    }
  }

  const handleChange = ({ target: { value } }) => {
    const textVal = value
    const result = valueSelect.map(({ label, value }) => {
      return label === 'Otro' ? { value, other: textVal } : { value }
    })
    onChange({ value: result, index })
  }

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0
    if (isMulti && primaryCondMaxId(index))
      return (
        <components.Menu {...props}>
          {secundConditionalMax(index, optionSelectedLength) ? (
            props.children
          ) : (
            <div style={{ margin: 15 }}>Maximo limite alcanzado</div>
          )}
        </components.Menu>
      )

    return <components.Menu {...props}>{props.children}</components.Menu>
  }

  useEffect(getAnswersRequest, [])

  if (idPrincipal === 32 && isHiddenA) return <></>

  if (isHiddenInfBasic) return <></>

  return (
    <Container>
      <P>{title === null ? '' : `${title}: ${result}`}</P>
      <Select
        className='selections-curser'
        components={{ Menu }}
        options={options}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        onChange={handleResult}
        styles={colorsSelect(errorSubmit)}
        isLoading={loading}
        loadingMessage='cargando...'
        placeholder={
          defaultValue === 0 || defaultValue === undefined
            ? 'Seleccionar'
            : result
        }
        maxMenuHeight={180}
      />

      {hiddenMore && (
        <>
          <p style={{ marginTop: 20, marginBottom: 5 }}>¿Cual?</p>
          <InputPrimary
            onChange={({ target: { value } }) =>
              !otherState
                ? handleResult({ val: value, label: 'OTRA' })
                : handleChange({ target: { value } })
            }
            style={{ width: '100%', height: 37, padding: '2px 8px' }}
          />
        </>
      )}
    </Container>
  )
}
