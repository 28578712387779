import axios from 'axios'
import { API_URL } from './urls'
import { configAxios } from './Authorization'

// https://apisec.corhuila.edu.co/api/admin/v1/users/index?page=

/**
 * get all users or filter the users by fullname
 * @param {String} token token that is used for admin to see all users in database
 * @param {String} added filter the users by your fullname
 * @returns {Promise<AxiosResponse>}
 */
export const getUsers = (token, added = '') =>
  axios.get(
    `${API_URL}/admin/v1/index${added !== '' ? '/' + added : ''}`,
    configAxios(token)
  )

/**
 * get current, next, prev or last page that have in the paginate-users
 * @param {String | Number} page the page currently or page that have navigate in the
 * users paginate
 * @param {String} token token that have admin for know all users
 * @returns {Promise<AxiosResponse>}
 */
export const getPaginateUsers = (page, token) =>
  axios.get(`${API_URL}/admin/v1/index?page=${page}`, configAxios(token))
