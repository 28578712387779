import { motion } from 'framer-motion'
import styled from 'styled-components'
import { loaderVariants } from '../../config/animates'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
  min-height: 500px;
`

export const Spinner = styled(motion.div)`
  width: 13px;
  height: 13px;
  background-color: #2d3134;
  border-radius: 50%;
`

export const SpinnerLoading = () => {
  return (
    <Container>
      <Spinner variants={loaderVariants} animate='animationOne' />
    </Container>
  )
}
