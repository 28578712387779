import Select from 'react-select'
import { useGetValueSelect } from '../../hooks/useGetValueSelect'
import { genderModel } from '../../models/filters'
import { selectLaborModel } from '../../models/selectLaborModel'
import { LabelCalendar } from '../LabelCalendar/LabelCalendar'
import { SelectFilter } from '../Select/SelectFilter'
import {
  WrapperForm,
  WrapperButton,
  Label,
  InputFilter,
  stylesCalendar,
  stylesTextCalendar,
  Button,
} from './styles'

export const FilterBasic = ({
  id = 1,
  refs = [],
  onChange,
  onChangeSelect,
  handleSubmitLabor,
}) => {
  const [dataProf, loadProf] = useGetValueSelect('professional')
  const [dataModa, loadModa] = useGetValueSelect('modalitie')
  const [dataFor, loadForm] = useGetValueSelect('formations')

  const handleFormat = (prevValue) => {
    const { dateChange, index } = prevValue
    const day = dateChange.getDate()
    const month = dateChange.getMonth() + 1
    const year = dateChange.getFullYear()
    const currentDate =
      month < 10 ? `${day}-0${month}-${year}` : `${day}-${month}-${year}`
    onChange({ target: { name: index, value: currentDate } })
  }

  return id === 1 ? (
    <>
      {/* section filter [basic] */}
      <WrapperForm>
        <Label htmlFor='nameUser'>Nombre o tarjeta de indentidad</Label>
        <InputFilter name='nameUser' id='nameUser' onChange={onChange} />
      </WrapperForm>
      <WrapperForm>
        <Select
          ref={refs[0]}
          options={genderModel}
          name='gender'
          onChange={onChangeSelect}
        />
      </WrapperForm>
    </>
  ) : id === 2 ? (
    <>
      {/* section filter [academic] */}
      <WrapperForm>
        <SelectFilter
          ref={refs[1]}
          name='title'
          titleLabel='Titulo profesional'
          loading={loadProf}
          onChange={onChangeSelect}
          options={dataProf}
          placeholder=''
        />
      </WrapperForm>
      <WrapperForm>
        <SelectFilter
          ref={refs[2]}
          name='modalities'
          titleLabel='Modalidad'
          loading={loadModa}
          onChange={onChangeSelect}
          options={dataModa}
          placeholder=''
        />
      </WrapperForm>
      <WrapperForm>
        <SelectFilter
          ref={refs[3]}
          name='formations'
          titleLabel='Formación'
          loading={loadForm}
          onChange={onChangeSelect}
          options={dataFor}
          placeholder=''
        />
      </WrapperForm>
      <WrapperForm>
        <LabelCalendar
          name='dateFrom'
          isFilter={true}
          question='Fecha desde'
          stylesContainer={stylesCalendar}
          stylesText={stylesTextCalendar}
          onChange={handleFormat}
        />
      </WrapperForm>
      <WrapperForm>
        <LabelCalendar
          name='dateTo'
          isFilter={true}
          question='Fecha hasta'
          stylesContainer={stylesCalendar}
          stylesText={stylesTextCalendar}
          onChange={handleFormat}
        />
      </WrapperForm>
    </>
  ) : id === 3 ? (
    <>
      {/* section filter [labor] */}
      <WrapperForm>
        <WrapperButton>
          {selectLaborModel.map(({ label, value, color }) => (
            <Button
              key={value}
              type='button'
              onClick={() => handleSubmitLabor(value)}
              colorHover={color}
            >
              {label}
            </Button>
          ))}
        </WrapperButton>
      </WrapperForm>
    </>
  ) : (
    <>
      {/* section filter [program] */}
      <WrapperForm>
        <Label htmlFor='program'>Programa</Label>
        <InputFilter name='program' id='program' onChange={onChange} />
      </WrapperForm>
    </>
  )
}
