import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { optionIcon } from '../../config/optionIcon'
import { Card } from './Card'

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    justify-content: space-around;
    padding: 30px 10px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 10px;
  }
`

export const ContentCard = ({ handleClick }) => {
  const navigate = useHistory()

  const handleNavigation = () => navigate.push('/admin/users')

  return (
    <Content>
      <Card id={1} primaryTitle='Información basica' onClick={handleClick} />
      <Card
        id={2}
        primaryTitle='Información academica'
        optionIcon={optionIcon.comboChart}
        bgCard='#F9B115'
        bgHover='#de9e15'
        onClick={handleClick}
      />
      <Card
        id={3}
        primaryTitle='Información laboral'
        optionIcon={optionIcon.lineChart}
        bgCard='#E55353'
        bgHover='#c14444'
        onClick={handleClick}
      />
      <Card
        id={7}
        primaryTitle='Encuesta'
        optionIcon={optionIcon.pieChart}
        bgCard='#3399FF'
        bgHover='#2b7ccd'
        onClick={handleNavigation}
      />
      <Card
        id={5}
        primaryTitle='Apreciación del programa'
        optionIcon={optionIcon.droplet}
        bgCard='#52be80'
        bgHover='#229954'
        onClick={handleClick}
      />
    </Content>
  )
}
