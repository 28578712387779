import { useState } from 'react'
import {
  getInfAcademicFilter,
  getInfLabor,
  getInformationBasicAdmin,
} from '../apis/Admin'

export const useCaseFilter = () => {
  const [dataResult, setDataResult] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const switchCaseGetting = async (
    dataFilter,
    token,
    idQuestions,
    laborId = 3
  ) => {
    try {
      setLoading(true)
      setError(false)
      let data
      switch (idQuestions) {
        case 1: {
          const {
            status,
            data: { message },
          } = await getInformationBasicAdmin(
            dataFilter.nameUser ? dataFilter.nameUser.toUpperCase() : '',
            dataFilter.gender,
            token
          )
          data = status === 204 ? {} : message
          break
        }
        case 2: {
          const {
            status,
            data: { message },
          } = await getInfAcademicFilter(dataFilter, token)
          data = status === 204 ? {} : message
          break
        }
        case 3: {
          const {
            status,
            data: { message },
          } = await getInfLabor(laborId, token)
          data = status === 204 ? {} : message
          break
        }
        case 8:
          console.log(idQuestions, 'case 4')
          break
        default:
          console.log(idQuestions, 'case 5')
          break
      }
      setLoading(false)
      setDataResult(data)
    } catch {
      setLoading(false)
      setError(true)
    }
  }

  return { dataResult, loading, error, switchCaseGetting }
}
