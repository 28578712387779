import { useState } from 'react'
import { loginModel } from '../../models/login'
import { ModalLogin } from './ModalLogin'
import { expresions } from '../../config/expresions'
import { postLogin } from '../../apis/endpoints'
import { useHandleSession } from '../../context/UserProvider'

export const LogicLogin = () => {
  const [values, setValues] = useState(loginModel)
  const [errorCedula, setErrorCedula] = useState(false)
  const [isHidden, setIsHidden] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { login } = useHandleSession()

  const handleHidden = (e) => {
    e.preventDefault()
    setIsHidden(!isHidden)
  }

  const handleChangeInput = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
    name === 'certification_number' && expresions.cedula.test(value)
      ? setErrorCedula(false)
      : name === 'certification_number' && setErrorCedula(true)
  }

  const signIn = async () => {
    if (
      !errorCedula &&
      values.certification_number !== '' &&
      values.password !== ''
    ) {
      try {
        setIsLoading(true)
        const {
          data: { ok, token, user },
        } = await postLogin(values)
        setIsLoading(false)
        ok && login(token, user)
      } catch {
        setIsLoading(false)
        setIsError(true)
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    signIn()
  }

  return (
    <ModalLogin
      values={values}
      errorCedula={errorCedula}
      isHidden={isHidden}
      handleChangeInput={handleChangeInput}
      handleHidden={handleHidden}
      handleSubmit={handleSubmit}
      isError={isError}
      setIsError={setIsError}
      isLoading={isLoading}
    />
  )
}
