export const pathVariants = {
  pressed: (isChecked) => ({
    pathLength: isChecked ? 0.85 : 0.2,
  }),
  checked: { pathLength: 1 },
  unChecked: { pathLength: 0 },
}

export const animateCycle = {
  start: {
    rotate: [0, 280, 280],
    borderRadius: ['20%', '50%', '50%'],
    backgroundColor: '#021529',
    borderColor: '#021529',
    transition: { duration: 0.5 },
  },
  finish: {
    rotate: [280, 280, 0],
    borderRadius: ['50%', '50%', '20%'],
    backgroundColor: '#FFF',
    borderColor: '#CCC',
    transition: { duration: 0.5 },
  },
}
