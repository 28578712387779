import { Portal } from '../../Portal/PortalLayourt'

export const PortalUser = ({
  id = 0,
  name = '',
  show = false,
  handlePortal,
}) => {
  return (
    <Portal titleModal={name} showModal={show} onClose={handlePortal}>
      <h2>
        {id}. Hi! user: {name}
      </h2>
    </Portal>
  )
}
