import { useEffect, useRef } from 'react'
import { FiSearch } from 'react-icons/fi'
import { FilterBasic } from './FilterBasic'
import { Container, Form, WrapperForm, Button } from './styles'
import { Spinner } from '../Spinners/SpinnerLoading'
import { useFilterProvider, useSubmitLabor } from '../../context/FilterProvider'
import { loaderVariants } from '../../config/animates'

export const CardFilter = ({ idQuestions = 1 }) => {
  const [
    filterData,
    _,
    loading,
    error,
    handleChange,
    handleChangeSelect,
    handleResetFitler,
    handleSubmitProvider,
    handleDownload,
  ] = useFilterProvider()
  const submitLabor = useSubmitLabor()
  const refInfBasic = useRef(null)
  const refAcademic = useRef(null)
  const refAcademic2 = useRef(null)
  const refAcademic3 = useRef(null)
  const allRef = [refInfBasic, refAcademic, refAcademic2, refAcademic3]

  const handleSubmit = (e) => {
    e.preventDefault()
    // Object.entries(filterData).length > 0 &&

    return idQuestions === 5 ? handleDownload() : handleSubmitProvider()
  }

  const handleSubmitLabor = (id) => {
    const laborId = { id, nameId: 'laborId' }
    // bussinessman || employed || independent || unemployed
    const dataFilter = {
      value:
        id === 3
          ? 'entrepreneur'
          : id === 4
          ? 'employee'
          : id === 5
          ? 'independent'
          : 'unemployed',
      name: 'laborName',
    }
    submitLabor(id, dataFilter, laborId)
  }

  const handleReset = () => {
    idQuestions === 1 && refInfBasic.current.select.clearValue()
    if (idQuestions === 2) {
      refAcademic.current.select.clearValue()
      refAcademic2.current.select.clearValue()
      refAcademic3.current.select.clearValue()
    }
    handleResetFitler()
  }

  useEffect(handleResetFitler, [idQuestions])

  return (
    <Container>
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        {idQuestions === 5 ? (
          ''
        ) : (
          <>
            <FilterBasic
              refs={allRef}
              id={idQuestions}
              data={filterData}
              onChange={handleChange}
              onChangeSelect={handleChangeSelect}
              handleSubmitLabor={handleSubmitLabor}
            />
            {loading && (
              <WrapperForm style={{ alignItems: 'center' }}>
                <Spinner
                  variants={loaderVariants}
                  animate='animationOne'
                  style={{ marginBottom: 10 }}
                />
              </WrapperForm>
            )}
          </>
        )}
        {error && (
          <WrapperForm>
            <p>Ha ocurrido un error al filtrar</p>
          </WrapperForm>
        )}
        {idQuestions !== 3 && (
          <WrapperForm
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Button idQuestions={idQuestions} type='submit'>
              {idQuestions === 5 ? (
                'Descargar'
              ) : (
                <>
                  <FiSearch size={20} style={{ marginRight: 5 }} />
                  Filtrar
                </>
              )}
            </Button>
            {idQuestions !== 5 && (
              <Button type='reset' onClick={handleReset}>
                Resetear los valores
              </Button>
            )}
          </WrapperForm>
        )}
      </Form>
    </Container>
  )
}
